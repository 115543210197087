import { useState } from "react";
import { useMediaQuery, Typography, useTheme, Box, Paper, Switch, Button } from "@mui/material";

import CustomTextField from "@root/kitUI/CustomTextField";
import Section from "@root/kitUI/section";

export default function CreateLink() {
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up("md"));
  const [isAddUtm, setIsAddUtm] = useState<boolean>(false);

  function createLinkHandler() {}

  return (
    <>
      <Section
        sxsect={{
          pt: upMd ? "130px" : "40px",
        }}
      >
        <Typography variant="h3">Создание ссылки</Typography>
        <Paper
          sx={{
            mt: upMd ? "35px" : "35px",
            p: "20px",
            backgroundColor: "#7E2AEA",
          }}
        >
          <Typography variant="h4" color="white">
            Создать новую ссылку
          </Typography>
          <CustomTextField
            id="link-input1"
            backgroundColor="#F2F3F7"
            placeholder="Введите ссылку"
            sx={{ mt: "30px" }}
          />
        </Paper>
        <Paper
          sx={{
            mt: upMd ? "30px" : "25px",
            p: "20px",
          }}
        >
          <Typography variant="t1" fontWeight={500}>
            Заголовок
          </Typography>
          <Typography
            sx={{
              mt: "7px",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "17px",
              color: "#9A9AAF",
            }}
          >
            (необязательно)
          </Typography>
          <CustomTextField id="link-input2" backgroundColor="#F2F3F7" placeholder="Введите текст" sx={{ mt: "9px" }} />
        </Paper>
        <Box
          sx={{
            mt: upMd ? "38px" : "32px",
            display: "flex",
            gap: "8px",
            alignItems: "center",
          }}
        >
          <Switch
            disableRipple
            sx={{
              "&:hover": {
                "& .MuiSwitch-thumb": {
                  borderColor: "#FB5709",
                },
                "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
                  backgroundColor: "#FB5709",
                },
              },
            }}
            checked={isAddUtm}
            onChange={(event, checked) => setIsAddUtm(checked)}
          />
          <Typography variant="h4">Добавление UTM</Typography>
        </Box>
        {isAddUtm && (
          <Paper
            sx={{
              mt: "19px",
              p: "20px",
              pt: upMd ? undefined : "23px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                rowGap: "28.5px",
                columnGap: "80px",
              }}
            >
              <CustomTextField
                id="company"
                label="Компания"
                placeholder="Компания"
                backgroundColor="#F2F3F7"
                sx={{
                  flexBasis: "100%",
                }}
              />
              <CustomTextField
                id="Subdid1"
                label="Subdid1"
                placeholder="Subdid1"
                backgroundColor="#F2F3F7"
                sx={{
                  flexBasis: upMd ? "33%" : "100%",
                  flexGrow: 1,
                }}
              />
              <CustomTextField
                id="Subdid2"
                label="Subdid2"
                placeholder="Subdid2"
                backgroundColor="#F2F3F7"
                sx={{
                  flexBasis: upMd ? "33%" : "100%",
                  flexGrow: 1,
                }}
              />
              <CustomTextField
                id="Subdid3"
                label="Subdid3"
                placeholder="Subdid3"
                backgroundColor="#F2F3F7"
                sx={{
                  flexBasis: upMd ? "33%" : "100%",
                  flexGrow: 1,
                }}
              />
              <CustomTextField
                id="Subdid4"
                label="Subdid4"
                placeholder="Subdid4"
                backgroundColor="#F2F3F7"
                sx={{
                  flexBasis: upMd ? "33%" : "100%",
                  flexGrow: 1,
                }}
              />
            </Box>
            <Typography variant="t1" fontWeight={500} mt="30px">
              Предварительный просмотр тега UTM
            </Typography>
            <Typography variant="t1" mt="13px">
              -
            </Typography>
          </Paper>
        )}
        <Typography variant="h4" mt={upMd ? "50px" : "50px"}>
          Ваша короткая ссылка
        </Typography>
        <Button
          disableRipple
          variant="template"
          sx={{
            mt: "30px",
            backgroundColor: "rgb(0 0 0 / 0)",
            borderColor: "#7E2AEA",
            color: "#7E2AEA",
            p: "9px 31px",
            "&:hover": {
              bgcolor: "background: rgba(0,0,0,.5)",
            },
            "&:active": {
              bgcolor: "#581CA7",
              color: "white",
            },
          }}
        >
          Сделать ссылку короткой
        </Button>
        <Paper
          sx={{
            mt: "20px",
            mb: "160px",
            p: "20px",
          }}
        >
          <CustomTextField
            disabled
            id="new-link"
            placeholder="Здесь будет ваша новая ссылка"
            backgroundColor="#F2F3F7"
          />
        </Paper>
        <Paper
          sx={{
            position: "fixed",
            right: upMd ? "calc((max(100%, 1200px) - 1160px) / 2)" : "16px",
            bottom: 0,
            p: "20px",
            width: upMd ? "max-content" : "min(100% - 32px, 280px)",
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
          }}
        >
          <Button
            disableRipple
            variant="template"
            sx={{
              p: "22px 50px",
              "&:hover": {
                bgcolor: "#944FEE",
              },
              "&:active": {
                bgcolor: "white",
                borderColor: "#944FEE",
                color: "#944FEE",
              },
            }}
            onClick={createLinkHandler}
          >
            Создать ссылку
          </Button>
        </Paper>
      </Section>
    </>
  );
}

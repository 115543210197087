import { MouseEventHandler, useState } from "react";
import { Typography, Button, Paper, SxProps, Theme, Box, IconButton } from "@mui/material";

import CustomTextField from "@root/kitUI/CustomTextField";
import CopyIcon from "@root/kitUI/Icons/CopyIcon";
import StatisticsIcon from "@root/kitUI/Icons/StatisticsIcon";

interface Props {
  link: {
    id: string;
    name: string;
    link: string;
    shortenedLink: string;
  };
  handleCopyClick?: MouseEventHandler<HTMLButtonElement>;
  handleStatisticsClick?: MouseEventHandler<HTMLButtonElement>;
  sx?: SxProps<Theme>;
}

export default function LinkCard({ link, handleCopyClick, handleStatisticsClick, sx }: Props) {
  const [isOpened, setIsOpened] = useState<boolean>(false);

  return (
    <Paper
      sx={{
        p: "20px",
        ...sx,
      }}
    >
      <Typography
        variant="t1"
        sx={{
          fontWeight: 500,
          overflow: "hidden",
          height: "20px",
          textOverflow: "ellipsis",
        }}
      >
        {link.name}
      </Typography>
      <CustomTextField
        multiline
        maxRows={3}
        disabled
        value={link.link}
        id={link.id}
        inputColor="#4D4D4D"
        sx={{ mt: "9px" }}
      />
      <Button
        disableRipple
        variant="underlined"
        onClick={() => setIsOpened((p) => !p)}
        sx={{
          mt: "30px",
          mb: "3px",
          "&:hover": {
            bgcolor: "white",
          },
        }}
      >
        {`${isOpened ? "Скрыть" : "Показать"} сокращенную ссылку`}
      </Button>
      {isOpened && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
          }}
        >
          <Typography
            variant="t1"
            sx={{
              mt: "10px",
              overflowWrap: "anywhere",
            }}
          >
            {link.shortenedLink}
          </Typography>
          <Box
            sx={{
              alignSelf: "end",
              display: "flex",
            }}
          >
            <IconButton disableRipple onClick={handleCopyClick} sx={{ p: "5px" }}>
              <CopyIcon />
            </IconButton>
            <IconButton disableRipple onClick={handleStatisticsClick} sx={{ p: "5px" }}>
              <StatisticsIcon />
            </IconButton>
          </Box>
        </Box>
      )}
    </Paper>
  );
}

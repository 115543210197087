import Section from "@root/kitUI/section";
import { Typography, Box, IconButton, Paper, useMediaQuery, useTheme, MenuItem } from "@mui/material";
import { useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import CustomSelect from "./CustomSelect";
import EditIcon from "./EditIcon";
import DateRangeSettings from "./DateRangeSettings";
import ChooseLink from "./ChooseLink";
import LinkStatTable from "./LinkStatTable";
import FilterIcon from "./FilterIcon";

const showTypeValues = ["Показать все", "Type value 2", "Type value 1", "Type value 3"] as const;
type ShowType = (typeof showTypeValues)[number];

const filterValues = ["Фильтр", "Filter value 1", "Filter value 2", "Filter value 3"] as const;
type FilterTypes = (typeof filterValues)[number];

export default function Stats() {
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up("md"));
  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs("2023-02-09"));
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs("2023-02-10"));
  const [showTypeValue, setShowTypeValue] = useState<ShowType>("Показать все");
  const [filterValue, setFilterValue] = useState<FilterTypes>("Фильтр");

  function handleChooseLink() {}

  return (
    <>
      <Section
        sxsect={{
          pt: upMd ? "130px" : "40px",
          pb: upMd ? "100px" : "20px",
        }}
      >
        <Typography variant="h3">Статистика</Typography>
        <ChooseLink sx={{ mt: "35px" }} onChooseClick={handleChooseLink} />
        <DateRangeSettings
          startDate={startDate}
          endDate={endDate}
          onStartDateChange={(e) => setStartDate(e)}
          onEndDateChange={(e) => setEndDate(e)}
          sx={{
            mt: upMd ? "30px" : "30px",
          }}
        />
        <Box
          sx={{
            mt: "40px",
            display: "flex",
            width: "100%",
            gap: "20px",
          }}
        >
          <CustomSelect
            id="select-showall"
            value={showTypeValue}
            onChange={(e) => setShowTypeValue(e.target.value as ShowType)}
          >
            {showTypeValues.map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </CustomSelect>
          {upMd ? (
            <CustomSelect
              id="select-filter"
              value={filterValue}
              onChange={(e) => setFilterValue(e.target.value as FilterTypes)}
            >
              {filterValues.map((value) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </CustomSelect>
          ) : (
            <IconButton
              disableRipple
              component={Paper}
              sx={{
                p: 0,
                ml: "5px",
                borderRadius: "8px",
                backgroundColor: "white",
                "&:hover": {
                  bgcolor: "#995DED",
                },
              }}
            >
              <FilterIcon />
            </IconButton>
          )}
          <IconButton
            disableRipple
            component={Paper}
            sx={{
              ml: "auto",
              p: 0,
              borderRadius: "8px",
              backgroundColor: "white",
            }}
          >
            <EditIcon />
          </IconButton>
        </Box>
        <LinkStatTable
          sx={{
            mt: upMd ? "20px" : "20px",
          }}
        />
      </Section>
    </>
  );
}

import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";

import PenaLogoLight from "@root/kitUI/img/logo_pena_links_light";
import Section from "@root/kitUI/section";

import BigNumberTextCard from "./BigNumberTextCard";
import LaptopWithTrails1 from "./LaptopWithTrails1";
import LaptopWithTrails2 from "./LaptopWithTrails2";

export default function Advantages() {
  const theme = useTheme();
  const upLg = useMediaQuery(theme.breakpoints.up("lg"));
  const upMd = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Section
      bg="#333647"
      sxsect={{
        pt: "174px",
        pb: upMd ? "117px" : "100px",
        overflow: "hidden",
      }}
    >
      <Typography
        variant="h3"
        sx={{
          color: "white",
          lineHeight: "36px",
          [theme.breakpoints.down("md")]: {
            lineHeight: "118.5%",
          },
        }}
      >
        Преимущества системы от
        <span
          style={{
            marginLeft: upMd ? "15px" : "12px",
          }}
        >
          <PenaLogoLight
            style={{
              height: upMd ? "37px" : "31px",
              width: upMd ? "96px" : "80px",
              minWidth: upMd ? "96px" : "80px",
              position: "relative",
              verticalAlign: "text-bottom",
            }}
            textColor="white"
          />
        </span>
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: upLg ? "row" : "column",
          mt: upLg ? "143px" : "23px",
        }}
      >
        <LaptopWithTrails1 />
        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "45px",
            flex: "1 1 0",
          }}
        >
          <BigNumberTextCard
            number="1"
            headerText="Удобный интерфейс"
            text="Текст-заполнитель — это текст, который имеет "
          />
          <BigNumberTextCard
            number="2"
            headerText="Личный кабинет"
            text="Текст-заполнитель — это текст, который имеет "
          />
          <BigNumberTextCard number="3" headerText="Гарантии" text="Текст-заполнитель — это текст, который имеет " />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: upLg ? "row" : "column",
          mt: upLg ? "210px" : "43px",
        }}
      >
        {!upLg && <LaptopWithTrails2 />}
        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "45px",
            flex: "1 1 0",
          }}
        >
          <BigNumberTextCard
            number="4"
            headerText="Простая кастомизация"
            text="Текст-заполнитель — это текст, который имеет "
          />
          <BigNumberTextCard
            number="5"
            headerText="Актуальная статистика"
            text="Текст-заполнитель — это текст, который имеет "
          />
          <BigNumberTextCard
            number="6"
            headerText="Еще преимущество"
            text="Текст-заполнитель — это текст, который имеет "
          />
        </Box>
        {upLg && <LaptopWithTrails2 />}
      </Box>
    </Section>
  );
}

export default function LogoutIcon() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.5601 12.3V15.25C12.5601 16.3546 11.6646 17.25 10.5601 17.25H3.69596C2.59139 17.25 1.69596 16.3546 1.69596 15.25V2.75C1.69596 1.64543 2.59139 0.75 3.69596 0.75H10.5601C11.6646 0.75 12.5601 1.64543 12.5601 2.75V5.7"
        stroke="#9A9AAF"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M15.067 11.475L16.8532 9.71165C17.2498 9.32011 17.2498 8.6799 16.8532 8.28836L15.067 6.52501"
        stroke="#9A9AAF"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path d="M16.7384 9L6.70996 9" stroke="#9A9AAF" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
}

import { Box, Button, IconButton, Paper, SxProps, Theme, Typography, useMediaQuery, useTheme } from "@mui/material";
import CustomTextField from "@root/kitUI/CustomTextField";
import CopyIcon from "@root/kitUI/Icons/CopyIcon";
import { MouseEventHandler } from "react";

interface Props {
  sx?: SxProps<Theme>;
  onChooseClick: MouseEventHandler<HTMLButtonElement>;
}

export default function ChooseLink({ sx, onChooseClick }: Props) {
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Paper
      sx={{
        p: "20px",
        ...sx,
      }}
    >
      <Typography variant="h4">Ваша сокращенная ссылка</Typography>
      <Box
        sx={{
          mt: "30px",
          display: "flex",
          flexWrap: upMd ? undefined : "wrap",
          justifyContent: "space-between",
          alignItems: "center",
          gap: upMd ? "4px" : "15px",
        }}
      >
        <CustomTextField
          id="shortened-link"
          placeholder="Выберите ссылку"
          backgroundColor="#F2F3F7"
          sx={{
            width: "100%",
            maxWidth: "670px",
            flexGrow: 1,
            flexBasis: upMd ? 0 : "100%",
            order: 1,
          }}
        />
        <IconButton
          disableRipple
          sx={{
            order: upMd ? 2 : 3,
          }}
        >
          <CopyIcon />
        </IconButton>
        <Button
          disableRipple
          onClick={onChooseClick}
          variant="template"
          sx={{
            p: "9px 31.5px",
            ml: upMd ? "auto" : undefined,
            flexGrow: 0,
            order: upMd ? 3 : 2,
            "&:hover": {
              bgcolor: "#944FEE",
            },
            "&:active": {
              bgcolor: "white",
              borderColor: "#944FEE",
              color: "#944FEE",
            },
          }}
        >
          Выбрать ссылку
        </Button>
      </Box>
    </Paper>
  );
}

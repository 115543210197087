import { Box, Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import Section from "@root/kitUI/section";

export default function RegisterNow() {
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up("md"));
  const upXl = useMediaQuery(theme.breakpoints.up("xl"));
  const isMobile = useMediaQuery(theme.breakpoints.up(600));

  return (
    <Section
      bg="#7E2AEA"
      sxsect={{
        p: "40px",
      }}
    >
      <Typography sx={{ fontSize: upXl ? "70px" : "55px" || isMobile ? "55px" : "36px" }} variant="h1" color="white">
        Начните управлять своим бизнесом уже сейчас
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: "20px",
          mt: upMd ? "50px" : "40px",
        }}
      >
        <Button
          disableRipple
          variant="template"
          sx={{
            color: "black",
            backgroundColor: "white",
            p: "9px 31px",
            "&:hover": {
              color: "white",
              bgcolor: "#944FEE",
              borderColor: "white",
            },
            "&:active": {
              bgcolor: "white",
              borderColor: "#944FEE",
              color: "#944FEE",
            },
          }}
        >
          Войти
        </Button>
        <Button
          disableRipple
          variant="template"
          sx={{
            color: "white",
            backgroundColor: "none",
            p: "9px 31px",
            border: "1px solid #FFFFFF",
            "&:hover": {
              borderColor: "white",
              bgColor: "#252734",
            },
            "&:active": {
              color: "#7E2AEA",
              borderColor: "#7E2AEA",
              background: "white",
            },
          }}
        >
          Регистрация
        </Button>
      </Box>
    </Section>
  );
}

import { MouseEventHandler, ReactNode } from "react";
import { Box, Button, Paper, SxProps, Theme, Typography, useMediaQuery, useTheme } from "@mui/material";

import LabeledDatePicker from "@root/kitUI/LabeledDatePicker";

import { Dayjs } from "dayjs";

interface Props {
  sx?: SxProps<Theme>;
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  onStartDateChange: (newValue: Dayjs | null) => void;
  onEndDateChange: (newValue: Dayjs | null) => void;
}

export default function DateRangeSettings({ sx, endDate, startDate, onStartDateChange, onEndDateChange }: Props) {
  const theme = useTheme();
  const upLg = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Paper
      sx={{
        p: "20px",
        display: "flex",
        flexDirection: upLg ? "row" : "column",
        gap: "30px",
        ...sx,
      }}
    >
      <Box>
        <Typography variant="h4">Период отображения статистики</Typography>
        <Box
          sx={{
            mt: "27px",
            display: "flex",
            gap: upLg ? "40px" : "30px",
            flexDirection: upLg ? "row" : "column",
            alignItems: "end",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: upLg ? "20px" : "13px",
              alignItems: upLg ? "start" : "end",
            }}
          >
            <LabeledDatePicker
              value={startDate}
              onChange={onStartDateChange}
              label="Дата начала"
              sx={{
                maxWidth: "169px",
              }}
            />
            <LabeledDatePicker
              value={endDate}
              onChange={onEndDateChange}
              label="Дата окончания"
              sx={{
                maxWidth: "169px",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              rowGap: "24px",
              columnGap: upLg ? "23px" : "15px",
              flexBasis: 0,
              flexGrow: 1,
            }}
          >
            <GreyUnderlineButton
            // TODO
            >
              Сегодня
            </GreyUnderlineButton>
            <GreyUnderlineButton
            // TODO
            >
              Вчера
            </GreyUnderlineButton>
            <GreyUnderlineButton
            // TODO
            >
              За последние 7 дней
            </GreyUnderlineButton>
            <GreyUnderlineButton
            // TODO
            >
              За последние 30 дней
            </GreyUnderlineButton>
            <GreyUnderlineButton
            // TODO
            >
              Этот месяц
            </GreyUnderlineButton>
            <GreyUnderlineButton
            // TODO
            >
              Прошлый месяц
            </GreyUnderlineButton>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: upLg ? "column" : "row-reverse",
          gap: "20px",
          alignSelf: upLg ? "end" : "stretch",
          justifyContent: "start",
          minWidth: upLg ? "180px" : undefined,
          flexWrap: "wrap",
        }}
      >
        <Button
          disableRipple
          variant="template"
          sx={{
            p: "9px 0px",
            maxWidth: "180px",
            flex: "1 0 0",
            minWidth: "min-content",
            "&:hover": {
              bgcolor: "#944FEE",
            },
            "&:active": {
              bgcolor: "white",
              borderColor: "#944FEE",
              color: "#944FEE",
            },
          }}
        >
          Применить
        </Button>
        <Button
          disableRipple
          variant="template"
          sx={{
            p: "9px 0px",
            backgroundColor: "rgb(0 0 0 / 0)",
            color: "#7E2AEA",
            maxWidth: "180px",
            flex: "1 0 0",
            minWidth: "min-content",
            "&:active": {
              borderColor: "#944FEE",
              color: "white",
              bgcolor: "#7E2AEA",
            },
          }}
        >
          Сбросить
        </Button>
      </Box>
    </Paper>
  );
}

function GreyUnderlineButton({
  children,
  onClick,
}: {
  children: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}) {
  return (
    <Button
      disableRipple
      variant="underlined"
      sx={{
        minWidth: 0,
        fontWeight: 400,
        color: "#9A9AAF",
        "&:hover": {
          color: "#7E2AEA",
          background: "none",
        },
      }}
      onClick={onClick}
    >
      {children}
    </Button>
  );
}

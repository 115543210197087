import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import Section from "@root/kitUI/section";
import Lights from "./Lights";

const infoBoxSx = {
  display: "flex",
  alignItems: "end",
  textAlign: "center",
  width: "100%",
  p: "20px",
};

export default function Profits() {
  const theme = useTheme();
  const upLg = useMediaQuery(theme.breakpoints.up("lg"));
  const upMd = useMediaQuery(theme.breakpoints.up("md"));
  const isTablet = useMediaQuery(theme.breakpoints.up(1000));
  const isHeader = useMediaQuery(theme.breakpoints.up(1200));

  const infoContainerSx = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
    gap: upLg ? "25px" : "15px",
    alignItems: "center",
    flex: "1 0 0",
  };

  const infoText1 = <InfoText text="Отслеживание изменений активности" />;
  const infoText2 = <InfoText text="Автоматизация процессов аналитики" />;
  const infoText3 = <InfoText text="Какая-то еще выгодная выгода" />;

  return (
    <Section
      bg="#262835"
      sxsect={{
        pb: upMd ? "100px" : "98px",
        pt: upMd ? "100px" : "102px",
        background: `
                    linear-gradient(284.12deg, rgba(0, 0, 0, 0.35) 3.47%, rgba(0, 0, 0, 0) 82.88%),
                    linear-gradient(0deg, #262835, #262835), #333647;
                `,
      }}
    >
      <Typography
        variant="h3"
        sx={{
          maxWidth: "480px",
          color: "white",
          lineHeight: "118.5%",
          [theme.breakpoints.down("md")]: {
            lineHeight: "118.5%",
          },
        }}
      >
        Выгода для бизнеса по мнению пользователей
      </Typography>
      <Box
        sx={{
          mt: upMd ? "75px" : "54px",
          display: "flex",
          gap: isTablet ? "100px" : "73px",
          flexDirection: isTablet ? "row" : "column",
        }}
      >
        <Box
          sx={{
            flex: "3.9 0 0",
            display: "flex",
            gap: "20px",
          }}
        >
          <Box sx={infoContainerSx}>
            <Typography
              variant="h4"
              sx={{
                color: "white",
                lineHeight: "118.5%",
              }}
            >
              42%
            </Typography>
            <Box
              sx={{
                ...infoBoxSx,
                borderRadius: upMd ? "16px" : "8px",
                mt: isTablet ? 0 : "5px",
                height: upMd ? "300px" : "147px",
                backgroundColor: "#7E2AEA",
                position: "relative",
                overflow: "hidden",
              }}
            >
              <Lights
                style={{
                  position: "absolute",
                  top: "0px",
                  left: "calc(50% - 2px)",
                  translate: "-50%",
                  width: "100%",
                }}
              />
              {isTablet && infoText1}
            </Box>
            {!isTablet && infoText1}
          </Box>
          <Box sx={infoContainerSx}>
            <Typography
              variant="h4"
              sx={{
                color: "white",
                lineHeight: "118.5%",
              }}
            >
              32%
            </Typography>
            <Box
              sx={{
                ...infoBoxSx,
                borderRadius: upMd ? "16px" : "8px",
                mt: isTablet ? 0 : "5px",
                height: upMd ? "230px" : "113px",
                backgroundColor: "#515675",
              }}
            >
              {isTablet && infoText2}
            </Box>
            {!isTablet && infoText2}
          </Box>
          <Box sx={infoContainerSx}>
            <Typography
              variant="h4"
              sx={{
                color: "white",
                lineHeight: "118.5%",
              }}
            >
              26%
            </Typography>
            <Box
              sx={{
                ...infoBoxSx,
                borderRadius: upMd ? "16px" : "8px",
                mt: isTablet ? 0 : "5px",
                height: upMd ? "150px" : "73px",
                backgroundColor: "#333647",
              }}
            >
              {isTablet && infoText3}
            </Box>
            {!isTablet && infoText3}
          </Box>
        </Box>
        <Box
          sx={{
            width: isHeader ? "200px" : "100%",
            display: "flex",
            flexDirection: "column",
            flex: "2 0 0",
          }}
        >
          <Typography
            sx={{
              color: "white",
              fontWeight: 500,
              fontSize: "24px",
              lineHeight: "28px",
            }}
          >
            Заголовок
          </Typography>
          <Typography color="white" variant="t1" mt="20px">
            Текст-заполнитель — это текст, который имеет некоторые,т, который имеет некоторые характеристики реального
            письменного текста
          </Typography>
          <Typography
            variant="t1"
            sx={{
              maxWidth: isTablet ? "355px" : undefined,
              color: "white",
              mt: isTablet ? "auto" : "40px",
              ml: isTablet ? undefined : "1.2em",
              position: "relative",
              "&::before": {
                content: '"*"',
                position: "absolute",
                left: "-1em",
                top: "0px",
                color: "#C293FF",
              },
            }}
          >
            Текст-заполнитель — это текст, который имеет
          </Typography>
        </Box>
      </Box>
    </Section>
  );
}

function InfoText({ text }: { text: string }) {
  const theme = useTheme();
  const upLg = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Typography
      color="white"
      variant="t1"
      sx={{
        fontWeight: "500",
        fontSize: upLg ? undefined : "13px",
        lineHeight: upLg ? undefined : "15px",
        textAlign: "center",
        maxWidth: upLg ? undefined : "110px",
      }}
    >
      {text}
    </Typography>
  );
}

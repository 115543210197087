import { Typography, Box, SxProps, Theme, useMediaQuery, useTheme } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

import { Dayjs } from "dayjs";

import CalendarIcon from "./Icons/CalendarIcon";

interface Props {
  label?: string;
  sx: SxProps<Theme>;
  value?: Dayjs | null;
  onChange?: (value: Dayjs | null) => void;
}

export default function LabeledDatePicker({ label, value, onChange, sx }: Props) {
  const theme = useTheme();
  const upLg = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Box
      sx={{
        ...sx,
      }}
    >
      {label && (
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "20px",
            color: "#4D4D4D",
            mb: "10px",
          }}
        >
          {label}
        </Typography>
      )}
      <DatePicker
        value={value}
        onChange={onChange}
        slots={{
          openPickerIcon: () => <CalendarIcon />,
        }}
        slotProps={{
          openPickerButton: {
            sx: {
              p: 0,
            },
          },
        }}
        sx={{
          "& .MuiInputBase-root": {
            backgroundColor: "#F2F3F7",
            borderRadius: "10px",
            pr: "22px",
            "& input": {
              py: "11px",
              pl: upLg ? "20px" : "13px",
              lineHeight: "19px",
            },
            "& fieldset": {
              borderColor: "#9A9AAF",
            },
          },
        }}
      />
    </Box>
  );
}

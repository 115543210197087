import { Box, SxProps, Theme } from "@mui/material";


const numberSvgs = {
    1: (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.2578 21C17.2284 21 21.2578 16.9706 21.2578 12C21.2578 7.02944 17.2284 3 12.2578 3C7.28725 3 3.25781 7.02944 3.25781 12C3.25781 16.9706 7.28725 21 12.2578 21Z" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" />
            <path d="M10.3828 9.375L12.6328 7.875V16.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    2: (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" />
            <path d="M9.92813 8.87848C10.1303 8.40097 10.4913 8.00797 10.9499 7.76595C11.4085 7.52393 11.9366 7.44775 12.4449 7.5503C12.9533 7.65284 13.4106 7.92782 13.7395 8.32871C14.0684 8.7296 14.2487 9.2318 14.25 9.75035C14.252 10.1984 14.118 10.6364 13.8656 11.0066V11.0066L9.75 16.5004H14.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    3: (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" />
            <path d="M9.75 7.875H14.25L11.625 11.625C12.0567 11.625 12.4817 11.7315 12.8624 11.935C13.243 12.1385 13.5677 12.4328 13.8075 12.7917C14.0473 13.1506 14.1949 13.5632 14.2372 13.9928C14.2795 14.4224 14.2152 14.8558 14.05 15.2546C13.8848 15.6534 13.6238 16.0053 13.2901 16.2791C12.9564 16.553 12.5603 16.7403 12.137 16.8246C11.7136 16.9088 11.276 16.8873 10.8629 16.762C10.4498 16.6367 10.074 16.4115 9.76875 16.1062" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    4: (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" />
            <path d="M11.625 7.125L9.375 13.5H13.875" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.875 10.5V16.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
};

type Numbers = keyof typeof numberSvgs;

interface Props {
    number: Numbers;
    color: string;
    backgroundColor: string;
    sx?: SxProps<Theme>;
}

export default function NumberIcon({ number, backgroundColor, color, sx }: Props) {
    const numberElement = numberSvgs[number];

    return (
        <Box sx={{
            backgroundColor,
            color,
            width: "36px",
            height: "36px",
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexShrink: 0,
            ...sx,
        }}>
            {numberElement}
        </Box>
    );
}
import { Box, SxProps, Theme } from "@mui/material";

interface Props {
  sx?: SxProps<Theme>;
}

export default function CloseIcon({ sx }: Props) {
  return (
    <Box
      sx={{
        width: "30px",
        height: "30px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexShrink: 0,
        "&:hover": {
          color: "#7E2AEA",
        },
        ...sx,
      }}
    >
      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 3L27 27M3 27L27 3" stroke="currentColor" />
      </svg>
    </Box>
  );
}

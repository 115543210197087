import { ReactNode, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Box, IconButton, Popover, Paper, useMediaQuery, useTheme } from "@mui/material";

import LogoutIcon from "./LogoutIcon";
import NavMenuItem from "../NavMenuItem";

import CustomAvatar from "./Avatar";
import PenaLogoDark from "../../img/logo_pena_links_dark";

export default function DesktopNavbar() {
  const [isPopperOpen, setIsPopperOpen] = useState<boolean>(false);
  const navbarRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const themeMUI = useTheme();
  const isTablet = useMediaQuery(themeMUI.breakpoints.up(1100));

  const handleClick = () => {
    setIsPopperOpen((prev) => !prev);
  };

  const handleClose = () => {
    setIsPopperOpen(false);
  };

  async function handleLogoutClick() {}

  useEffect(
    function closePopperOnLocationChange() {
      setIsPopperOpen(false);
    },
    [location.pathname]
  );

  return (
    <Box
      ref={navbarRef}
      component="nav"
      sx={{
        px: "16px",
        width: "100%",
        position: "fixed",
        display: "flex",
        height: "80px",
        alignItems: "center",
        background: "white",
        zIndex: "1000",
        borderBottom: "1px solid #E3E3E3",
      }}
    >
      <PenaLogoDark style={{ minWidth: "124px" }} />
      <Box
        sx={{
          display: "flex",
          gap: isTablet ? "14px" : "0",
          ml: isTablet ? "42px" : "30px",
          overflow: "hidden",
        }}
      >
        <NavMenuItem to="/links">Мои ссылки</NavMenuItem>
        <NavMenuItem to="/create" onClick={handleClick}>
          Создание ссылки
        </NavMenuItem>
        <NavMenuItem to="/">Кастомизация ссылки</NavMenuItem>
        <NavMenuItem to="/stats">Статистика</NavMenuItem>
        <NavMenuItem to="/chart">Пиксель</NavMenuItem>
      </Box>
      <Box
        sx={{
          display: "flex",
          ml: "auto",
        }}
      >
        <CustomAvatar
          sx={{
            ml: "27px",
            backgroundColor: "#FB5607",
            height: "36px",
            width: "36px",
            "&:hover": {
              border: " 2px solid #9A9AAF",
            },
            "&:active": {
              border: "2px solid black",
            },
            "&:active .buttonPress": {
              bgcolor: "#7E2AEA",
              fill: "#7E2AEA",
            },
          }}
        />
        <IconButton
          disableRipple
          onClick={handleLogoutClick}
          sx={{
            ml: "20px",
            bgcolor: "#F2F3F7",
            borderRadius: "6px",
            height: "36px",
            width: "36px",
            "&:hover": {
              bgcolor: "#9A9AAF",
            },
            "&:hover path": {
              stroke: "white",
            },
            "&:active": {
              bgcolor: "#7E2AEA",
            },
          }}
        >
          <LogoutIcon />
        </IconButton>
      </Box>
      <Popover
        id="nav-popover"
        open={isPopperOpen}
        anchorEl={isPopperOpen ? navbarRef.current : null}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        marginThreshold={0}
        sx={{
          "& .MuiPopover-paper": {
            width: "100vw",
            maxWidth: "100vw",
            borderRadius: 0,
          },
        }}
      >
        <Paper
          sx={{
            borderRadius: 0,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              "&:hover": {
                backgroundColor: "#F2F3F7",
              },
            }}
          >
            <MenuSubItem to="/create">Создание ссылки</MenuSubItem>
          </Box>
          <Box
            sx={{
              "&:hover": {
                backgroundColor: "#F2F3F7",
              },
            }}
          >
            <MenuSubItem to="/buy">Покупка и настройка custom ссылки</MenuSubItem>
          </Box>
          <Box
            sx={{
              "&:hover": {
                backgroundColor: "#F2F3F7",
              },
            }}
          >
            <MenuSubItem to="/">A/B тесты</MenuSubItem>
          </Box>
        </Paper>
      </Popover>
    </Box>
  );
}

function MenuSubItem({ children, to }: { to: string; children: ReactNode }) {
  return (
    <NavMenuItem
      to={to}
      defaultColor="#4D4D4D"
      sx={{
        fontWeight: 400,
        py: "15px",
        pl: "200px",
        justifyContent: "start",
        "&:hover": {
          backgroundColor: "#F2F3F7",
        },
      }}
    >
      {children}
    </NavMenuItem>
  );
}

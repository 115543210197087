import { Paper, SxProps, Theme } from "@mui/material";
import { ChartData, Point } from "chart.js";
import { useEffect, useMemo, useRef } from "react";
import { Line } from 'react-chartjs-2';
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";
import { chartjsOptions } from "./config";


interface Props {
    transitionsPointData: Point[];
    uniqueTransitionsPointData: Point[];
    sx?: SxProps<Theme>;
}

export default function Chart({ transitionsPointData, uniqueTransitionsPointData, sx }: Props) {
    const chartRef = useRef<ChartJSOrUndefined<"line">>();

    useEffect(function updateChartOnFontLoad() {
        document.fonts.ready.then(() => {
            chartRef.current?.update();
        });
    }, []);

    const chartData: ChartData<"line"> = useMemo(() => ({
        datasets: [
            {
                label: "Переходы",
                data: transitionsPointData,
                borderColor: "#7E2AEA",
                backgroundColor: "#7E2AEA",
                yAxisID: "y",
                cubicInterpolationMode: "monotone",
                normalized: true,
            },
            {
                label: "Уникальные переходы",
                data: uniqueTransitionsPointData,
                borderColor: "#FB5607",
                backgroundColor: "#FB5607",
                yAxisID: "y",
                cubicInterpolationMode: "monotone",
                normalized: true,
            },
        ],
    }), [transitionsPointData, uniqueTransitionsPointData]);

    return (
        <Paper sx={{
            maxWidth: "722px",
            height: "585px",
            py: "20px",
            pr: "20px",
            ...sx,
        }}>
            <Line ref={chartRef} options={chartjsOptions} data={chartData} />
        </Paper>
    );
}
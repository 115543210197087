import { useMediaQuery, Box, Button, useTheme } from "@mui/material";

import NavMenuItem from "../NavMenuItem";

import SectionStyled from "@kitUI/section";
import LogoLight from "@kitUI/img/logo_pena_links_light";

interface Props {
  menuItems: { path: string; title: string }[];
}

export default function DesktopLandingNavbar({ menuItems }: Props) {
  const themeMUI = useTheme();
  const isTablet = useMediaQuery(themeMUI.breakpoints.up(1100));

  return (
    <SectionStyled
      tag="header"
      mwidth="1200px"
      sxsect={{
        minHeight: "90px",
        position: "fixed",
        zIndex: themeMUI.zIndex.header,
        top: 0,
        borderBottom: "1px solid #434654",
          background: "linear-gradient(90deg, rgba(51,54,64,1) 0%, rgba(37,39,48,1) 100%)"
      }}
      sxcont={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <LogoLight style={{ minWidth: "150px" }} textColor="white" />
      <Box
        sx={{
          display: "flex",
          gap: isTablet ? "14px" : "0",
          ml: isTablet ? "42px" : "30px",
          overflow: "hidden",
        }}
      >
        {menuItems.map((element) => (
          <NavMenuItem mode="dark" key={element.title} to={element.path}>
            {element.title}
          </NavMenuItem>
        ))}
      </Box>
      <Box
        sx={{
          ml: "auto",
          display: "flex",
          gap: "20px",
        }}
      >
        <Button
          variant="outlined"
          disableRipple
          sx={{
            color: "white",
            border: "1px solid white",
            textTransform: "none",
            fontWeight: "400",
            fontSize: "18px",
            lineHeight: "24px",
            borderRadius: "8px",
            p: "9px 31px",
            "&:hover": {
              borderColor: "white",
              bgColor: "#252734",
            },
            "&:active": {
              borderColor: "#7E2AEA",
              background: "#7E2AEA",
            },
          }}
        >
          Регистрация
        </Button>
        <Button
          disableRipple
          variant="template"
          sx={{
            p: "9px 31px",
            "&:hover": {
              border: "1px solid #944FEE",
              bgcolor: "#944FEE",
            },
            "&:active": {
              borderColor: "#944FEE",
              bgcolor: "white",
              color: "#944FEE",
            },
          }}
        >
          Войти
        </Button>
      </Box>
    </SectionStyled>
  );
}

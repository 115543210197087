import { useState } from "react";
import { Typography, Box, useMediaQuery, useTheme, Pagination } from "@mui/material";

import Section from "@root/kitUI/section";

import ColumnLayout from "./ColumnLayout";
import LinkCard from "./LinkCard";

const links = createExampleLinks(12);

export default function MyLinks() {
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up("md"));
  const [currentPage, setCurrentPage] = useState<number>(0);

  const pagination = (
    <Pagination
      shape="rounded"
      count={5}
      page={currentPage + 1}
      onChange={(event, value) => setCurrentPage(value - 1)}
    />
  );

  return (
    <>
      <Section
        sxsect={{
          pt: upMd ? "130px" : "40px",
          pb: upMd ? "115px" : "20px",
        }}
      >
        <Typography variant="h3">Мои ссылки</Typography>
        {!upMd && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: "40px",
            }}
          >
            {pagination}
          </Box>
        )}
        <ColumnLayout
          sx={{
            mt: !upMd ? "30px" : "40px",
          }}
        >
          {links.map((link) => (
            <LinkCard link={link} key={link.id} />
          ))}
        </ColumnLayout>
        <Box
          sx={{
            display: "flex",
            justifyContent: upMd ? "end" : "center",
            mt: "32px",
          }}
        >
          {pagination}
        </Box>
      </Section>
    </>
  );
}

function createExampleLinks(amount: number): {
  id: string;
  name: string;
  link: string;
  shortenedLink: string;
}[] {
  const links = [];
  const names = ["Без названия", "Без названия", "Пена", "ФармКорп", "Очень длинное название ссылки на две строки"];
  for (let index = 0; index < amount; index++) {
    links.push({
      id: `link-field-${index}`,
      name: names[Math.floor(Math.random() * names.length)],
      link: "https://link.fynrods.ru/?p=cfii5ug6bi7pt6s4ihr0utm_medium=cpc&utm_source=yandex&utm_campaign=Y_Brand_Search_Rus%7C70301404",
      shortenedLink: "https://link.fynrods.ru/?p=cfii5ug6bi7pt6s4ihr0utm_medium=cpc",
    });
  }

  return links;
}

import { Typography, Box, SxProps, Theme } from "@mui/material";
import { ReactNode } from "react";


interface Props {
    icon: ReactNode;
    image: string;
    headerText: string;
    text: string;
    sx?: SxProps<Theme>;
}

export default function ImageTextNumberCard({ icon, headerText, sx, text, image }: Props) {

    return (
        <Box
            sx={{
                p: "20px",
                borderRadius: "8px",
                backgroundColor: "#434556",
                boxShadow: `
                    0px 100px 309px rgba(37, 39, 52, 0.24), 
                    0px 41.7776px 129.093px rgba(37, 39, 52, 0.172525), 
                    0px 22.3363px 69.0192px rgba(37, 39, 52, 0.143066), 
                    0px 12.5216px 38.6916px rgba(37, 39, 52, 0.12), 
                    0px 6.6501px 20.5488px rgba(37, 39, 52, 0.0969343), 
                    0px 2.76726px 8.55082px rgba(37, 39, 52, 0.0674749)
                `,
                ...sx,
            }}
        >
            <Box sx={{
                position: "relative",
                width: "100%",
                height: "225px",
                borderRadius: "8px",
                background: `center / cover no-repeat url(${image})`,
            }}>
                <Box sx={{
                    position: "absolute",
                    top: "20px",
                    right: "20px",
                }}>
                    {icon}
                </Box>
            </Box>
            <Typography sx={{
                fontWeight: 500,
                fontSize: "24px",
                lineHeight: "28px",
                mt: "45px",
                maxWidth: "215px",
                color: "white",
            }}>{headerText}</Typography>
            <Typography mt="14px" maxWidth="250px" color="white" variant="t1">{text}</Typography>
        </Box>
    );
}
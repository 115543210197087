import { CSSProperties } from "react";

interface Props {
  style?: CSSProperties;
}

export default function Lights({ style }: Props) {
  return (
    <svg width="196" height="175" viewBox="0 0 196 175" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path
        d="M148.919 117.806C148.832 118.245 149.047 118.688 149.446 118.891C149.845 119.094 150.33 119.008 150.634 118.679L194.734 71.0005C194.96 70.7563 195.05 70.4159 194.973 70.0922C194.897 69.7685 194.665 69.5038 194.354 69.3862L169.945 60.15L176.081 29.1944C176.168 28.7554 175.953 28.312 175.554 28.1089C175.155 27.9058 174.67 27.9924 174.366 28.321L130.266 75.9996C130.04 76.2437 129.95 76.5841 130.027 76.9078C130.103 77.2315 130.335 77.4962 130.646 77.6139L155.055 86.85L148.919 117.806Z"
        fill="url(#paint0_linear_522_580)"
        stroke="url(#paint1_linear_522_580)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.9831 173.858C40.9396 174.077 41.0473 174.299 41.2467 174.4C41.4461 174.502 41.6887 174.459 41.8406 174.294L101.279 110.033C101.392 109.911 101.437 109.741 101.399 109.579C101.36 109.417 101.244 109.285 101.089 109.226L67.5199 96.5232L75.9288 54.0972C75.9723 53.8777 75.8647 53.656 75.6652 53.5545C75.4658 53.4529 75.2232 53.4962 75.0713 53.6605L15.6329 117.922C15.52 118.044 15.4752 118.214 15.5133 118.376C15.5514 118.538 15.6675 118.67 15.823 118.729L49.392 131.432L40.9831 173.858Z"
        fill="url(#paint2_linear_522_580)"
        stroke="url(#paint3_linear_522_580)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M98.4966 45.2711C98.4096 45.7101 98.6249 46.1535 99.0237 46.3566C99.4225 46.5597 99.9077 46.4731 100.212 46.1445L131.659 12.1451C131.885 11.901 131.975 11.5606 131.898 11.2369C131.822 10.9132 131.59 10.6485 131.279 10.5308L114.1 4.0305L118.428 -17.8056C118.515 -18.2446 118.3 -18.688 117.901 -18.8911C117.503 -19.0942 117.017 -19.0076 116.713 -18.679L85.2659 15.3204C85.0401 15.5645 84.9504 15.9049 85.0266 16.2286C85.1029 16.5523 85.3351 16.817 85.6461 16.9347L102.825 23.435L98.4966 45.2711Z"
        fill="url(#paint4_linear_522_580)"
        stroke="url(#paint5_linear_522_580)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9188 60.8071C10.8324 61.2467 11.0489 61.6902 11.4487 61.8924C11.8485 62.0946 12.334 62.0062 12.6369 61.676L35.7369 36.4975C35.9609 36.2533 36.0494 35.914 35.9732 35.5915C35.897 35.269 35.666 35.0052 35.3564 34.8871L22.9438 30.1518L26.0812 14.1929C26.1676 13.7533 25.9511 13.3098 25.5513 13.1076C25.1515 12.9054 24.666 12.9938 24.3631 13.324L1.26313 38.5025C1.03912 38.7467 0.950604 39.086 1.02679 39.4085C1.10297 39.731 1.33396 39.9948 1.64356 40.1129L14.0562 44.8482L10.9188 60.8071Z"
        fill="url(#paint6_linear_522_580)"
        stroke="url(#paint7_linear_522_580)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M121.409 173.904C121.366 174.124 121.475 174.346 121.675 174.447C121.876 174.547 122.119 174.502 122.27 174.337L138.37 156.658C138.481 156.536 138.525 156.367 138.487 156.206C138.448 156.045 138.334 155.914 138.179 155.855L129.371 152.47L131.591 141.096C131.634 140.876 131.525 140.654 131.325 140.553C131.124 140.453 130.881 140.498 130.73 140.663L114.63 158.342C114.519 158.464 114.475 158.633 114.513 158.794C114.552 158.955 114.666 159.086 114.821 159.145L123.629 162.53L121.409 173.904Z"
        fill="url(#paint8_linear_522_580)"
        stroke="url(#paint9_linear_522_580)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_522_580"
          x1="134.5"
          y1="118"
          x2="173.136"
          y2="33.7566"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7E2AEA" />
          <stop offset="1" stopColor="#A56BF1" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_522_580"
          x1="134.5"
          y1="118"
          x2="173.136"
          y2="33.7566"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7E2AEA" />
          <stop offset="1" stopColor="#A56BF1" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_522_580"
          x1="3.5"
          y1="146"
          x2="72.7907"
          y2="60.411"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7E2AEA" />
          <stop offset="1" stopColor="#A56BF1" stopOpacity="0.38" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_522_580"
          x1="3.5"
          y1="146"
          x2="72.7907"
          y2="60.411"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7E2AEA" />
          <stop offset="1" stopColor="#A56BF1" stopOpacity="0.38" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_522_580"
          x1="97.3203"
          y1="59.0215"
          x2="116.047"
          y2="-14.6081"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7E2AEA" />
          <stop offset="1" stopColor="#C08EFF" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_522_580"
          x1="97.3203"
          y1="59.0215"
          x2="116.047"
          y2="-14.6081"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7E2AEA" />
          <stop offset="1" stopColor="#C08EFF" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_522_580"
          x1="5"
          y1="61"
          x2="35.2289"
          y2="2.11754"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7E2AEA" />
          <stop offset="1" stopColor="#C08EFF" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_522_580"
          x1="5"
          y1="61"
          x2="35.2289"
          y2="2.11754"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7E2AEA" />
          <stop offset="1" stopColor="#C08EFF" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_522_580"
          x1="111.614"
          y1="166.31"
          x2="138.507"
          y2="132.908"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7E2AEA" />
          <stop offset="1" stopColor="#A56BF1" stopOpacity="0.17" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_522_580"
          x1="111.614"
          y1="166.31"
          x2="138.507"
          y2="132.908"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7E2AEA" />
          <stop offset="1" stopColor="#A56BF1" stopOpacity="0.17" />
        </linearGradient>
      </defs>
    </svg>
  );
}

import { useState } from "react";
import { Box, FormControl, IconButton, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";

import ChainLinks from "./ChainLinks";
import StatisticsIcon from "./StatisticsIcon";

import Section from "@root/kitUI/section";

export default function Hero() {
  const theme = useTheme();
  const upLg = useMediaQuery(theme.breakpoints.up("lg"));
  const isMobile = useMediaQuery(theme.breakpoints.up(600));
  const isTablet = useMediaQuery(theme.breakpoints.up(1100));
  const [linkField, setLinkField] = useState<string>("");

  return (
    <Section
      bg="#333647"
      sxsect={{
        background: "linear-gradient(90deg, rgba(51,54,64,1) 0%, rgba(37,39,48,1) 100%)",
        pt: upLg ? "217px" : "51px",
        pb: upLg ? "171px" :  isMobile ? "60px" : "0",
          height: isMobile ? "auto" : "100vh"
      }}
      sxcont={{
        position: "relative",
      }}
    >
      {!upLg && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: isMobile ? "100px" : "0",
          }}
        >
          <ChainLinks
            style={{
              width: "100%",
                height: isMobile ? "100%" : "180px",
              maxHeight: "274px",
              pointerEvents: "none",
            }}
          />
        </Box>
      )}
      <Box
        sx={{
          position: "relative",
          zIndex: theme.zIndex.content,
          mt: upLg ? undefined : "11px",
        }}
      >
        <Typography sx={{ fontSize: isTablet ? "70px" : "55px" }} variant="h1" color="white" maxWidth="690px">
          Управляйте вашими ссылками
        </Typography>
        <Typography
          variant="t1"
          sx={{
            color: "white",
            maxWidth: isTablet ? "681px" : "600px",
            mt: upLg ? "30px" : "20px",
          }}
        >
          Текст-заполнитель — это текст, который имеет некоторые характеристики реального письменного текста, но
          является
        </Typography>
        <Box
          sx={{
            mt: upLg ? "85px" : "40px",
            display: "flex",
            gap: "20px",
            alignItems: "end",
            maxWidth: "624px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              flexGrow: 1,
            }}
          >
            <Typography
              variant="t1"
              sx={{
                color: "#C293FF",
                fontWeight: 500,
                maxWidth: "85%",
              }}
            >
              Посмотрите статистику по вашей ссылке
            </Typography>
            <FormControl variant="standard" fullWidth>
              <TextField
                id="link"
                placeholder="Введите ссылку"
                value={linkField}
                onChange={(e) => setLinkField(e.target.value)}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "10px",
                    backgroundColor: "#2A2C3A",
                    color: "white",
                    "& fieldset": {
                      border: "1px solid #9A9AAF",
                    },
                    "&:hover fieldset": {
                      borderColor: "#5b5d6e",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#944FEE",
                    },
                    "& ::placeholder": {
                      color: "#9A9AAF",
                      opacity: 1,
                    },
                    "& input": {
                      p: "11px 20px",
                    },
                  },
                }}
              />
            </FormControl>
          </Box>
          <IconButton
            disableRipple
            sx={{
              p: 0,
              height: "44px",
              width: "44px",
            }}
          >
            <StatisticsIcon />
          </IconButton>
        </Box>
      </Box>
      {upLg && (
        <ChainLinks
          style={{
            position: "absolute",
            top: "-12px",
            right: "15px",
            zIndex: 0,
          }}
        />
      )}
    </Section>
  );
}

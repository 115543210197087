import zoomPlugin from "chartjs-plugin-zoom";
import "chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm";
import { Chart, Tooltip, CategoryScale, Legend, LinearScale, LineElement, PointElement, TimeScale, Title, ChartOptions, Point } from "chart.js";


export const chartjsOptions: ChartOptions<"line"> = {
    responsive: true,
    maintainAspectRatio: false,
    parsing: false,
    elements: {
        point: {
            radius: 0,
            hoverRadius: 6,
            hoverBorderWidth: 3,
            hoverBackgroundColor: "white",
        },
        line: {
            borderJoinStyle: "round",
            tension: 0.4,
        },
    },
    interaction: {
        mode: "nearest",
        intersect: false,
        axis: "xy",
    },
    plugins: {
        zoom: {
            zoom: {
                wheel: {
                    enabled: true,
                    speed: 0.1,
                },
                pinch: {
                    enabled: true,
                },
                mode: "x",
                drag: {
                    enabled: true,
                    modifierKey: "shift",
                    borderColor: "rgb(54, 162, 235)",
                    borderWidth: 1,
                    backgroundColor: "rgba(54, 162, 235, 0.3)",
                },
                // onZoomComplete: (...args) => console.log("onZoomComplete event with args:", ...args)
            },
            pan: {
                enabled: true,
                mode: "x",
                // onPanComplete: (...args) => console.log("onPanComplete event with args:", ...args)
            },
            limits: {
                x: {
                    min: "original",
                    max: "original",
                }
            }
        },
        legend: {
            align: "start",
            fullSize: false,
            labels: {
                color: "black",
                boxHeight: 1,
                boxWidth: 25,
                font: {
                    size: 18,
                    family: "Rubik",
                    weight: "normal",
                }
            },
        },
        tooltip: {
            backgroundColor: ctx => (ctx.tooltipItems[0]?.dataset.backgroundColor ?? "rgb(0 0 0 / 0.2") as string,
            xAlign: "center",
            yAlign: "bottom",
            callbacks: {
                title: () => "",
                label: ctx => ctx.formattedValue,
                footer: () => "",
            },
            displayColors: false,
            padding: {
                x: 19,
                y: 7,
            },
            bodyFont: {
                size: 18,
                family: "Rubik",
                weight: "normal",
            },
            filter: (e, index) => index === 0,
            caretPadding: 12,
            caretSize: 0,
        },
    },
    scales: {
        x: {
            type: "time",
            bounds: "data",
            time: {
                displayFormats: {
                    day: "YYYY-MM-DD",
                },
                unit: "day",
            },
            grid: {
                display: false,
            },
            border: {
                color: "#9A9AAF80"
            },
            ticks: {
                minRotation: 90,
                maxRotation: 90,
                autoSkipPadding: 80,
                color: "black",
                font: {
                    size: 16,
                    family: "Rubik",
                    weight: "normal",
                },
            },
        },
        y: {
            type: "linear",
            grid: {
                drawTicks: false,
                color: "#9A9AAF80",
            },
            ticks: {
                padding: 20,
                color: "black",
                font: {
                    size: 16,
                    family: "Rubik",
                    weight: "normal",
                },
                stepSize: 6,
            },
            border: {
                color: "#9A9AAF80"
            },
        }
    },
    onClick: (event, elements, chart) => {
        if (!elements?.length) return;

        const datasetIndex = elements[0].datasetIndex;
        const elementIndex = elements[0].index;
        const point = chart.data.datasets[datasetIndex].data[elementIndex] as Point;

        console.log(`timestamp: ${point.x}, value: ${point.y}`);
    },
};

export const legendBottomPaddingPlugin = {
    id: "increase-legend-spacing",
    beforeInit(chart: any) {
        const originalFit = chart.legend.fit;
        chart.legend.fit = function fit() {
            originalFit.bind(chart.legend)();
            this.height += 20;
        };
    }
};

export const verticalLineOnHoverPlugin = {
    id: "vertical_line_on_hover",
    afterDraw: (chart: any) => {
        if (!chart?.tooltip?._active?.length) return;

        const activePoint = chart.tooltip._active[0];
        const ctx = chart.ctx as CanvasRenderingContext2D;
        const x = activePoint?.element?.x;
        const topY = activePoint?.element?.y + 12;
        const bottomY = chart.scales.y.bottom;
        if (!x) return;

        ctx.save();
        ctx.beginPath();
        ctx.setLineDash([5, 10]);
        ctx.lineCap = "round";
        ctx.strokeStyle = chart.tooltip.labelColors[0].backgroundColor;
        ctx.moveTo(x, topY);
        ctx.lineTo(x, bottomY);
        ctx.lineWidth = 2;
        ctx.stroke();
        ctx.restore();
    }
};

Chart.register(
    CategoryScale,
    LinearScale,
    TimeScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    zoomPlugin,
    legendBottomPaddingPlugin,
    verticalLineOnHoverPlugin,
);
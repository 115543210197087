import { ReactNode, forwardRef, useEffect, useRef, useState } from "react";
import { useLocation, Link } from "react-router-dom";

import { Box, IconButton, AppBar, Toolbar, useTheme, Slide, Dialog } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";

import CloseIcon from "@root/kitUI/Icons/CloseIcon";
import LogoLight from "@kitUI/img/logo_pena_links_light";
import BurgerIcon from "@root/kitUI/Icons/BurgerIcon";

import LogoutIcon from "./LogoutIcon";
import NavMenuItem from "../NavMenuItem";
import CustomAvatar from "./Avatar";

import PenaLogoDark from "../../img/logo_pena_links_dark";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const logoStyle = { minWidth: "101px", width: "101px", height: "39px" };

export default function TabletNavbar() {
  const theme = useTheme();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const location = useLocation();
  const [isPopperOpen, setIsPopperOpen] = useState<boolean>(false);
  const navbarRef = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    setIsPopperOpen((prev) => !prev);
  };
  const handleClose = () => {
    setIsPopperOpen(false);
  };

  const dialogClose = () => {
    setIsDialogOpen(false);
    setIsPopperOpen(false);
  };

  async function handleLogoutClick() {}

  useEffect(
    function closePopperOnLocationChange() {
      setIsPopperOpen(false);
      handleClose();
    },
    [location.pathname]
  );

  return (
    <Box
      ref={navbarRef}
      component="nav"
      sx={{
        px: "16px",
        display: "flex",
        width: "100%",
        position: "fixed",
        height: "80px",
        alignItems: "center",
        bgcolor: "white",
        zIndex: "1000",
        borderBottom: "1px solid #E3E3E3",
      }}
    >
      <PenaLogoDark style={{ minWidth: "124px" }} />

      <Box
        sx={{
          display: "flex",
          ml: "auto",
        }}
      >
        <CustomAvatar
          sx={{
            ml: "27px",
            backgroundColor: "#FB5607",
            height: "36px",
            width: "36px",
            "&:hover": {
              border: " 2px solid #9A9AAF",
            },
            "&:active": {
              border: "2px solid black",
            },
            "&:active .buttonPress": {
              bgcolor: "#7E2AEA",
              fill: "#7E2AEA",
            },
          }}
        />
        <IconButton
          disableRipple
          onClick={handleLogoutClick}
          sx={{
            ml: "20px",
            bgcolor: "#F2F3F7",
            borderRadius: "6px",
            height: "36px",
            width: "36px",
            "&:hover": {
              bgcolor: "#9A9AAF",
            },
            "&:hover path": {
              stroke: "white",
            },
            "&:active": {
              bgcolor: "#7E2AEA",
            },
          }}
        >
          <LogoutIcon />
        </IconButton>
      </Box>
      <IconButton onClick={() => setIsDialogOpen(true)} sx={{ p: 0, ml: "40px" }}>
        <BurgerIcon sx={{ color: "black" }} />
      </IconButton>
      <Dialog
        fullScreen
        open={isDialogOpen}
        onClose={dialogClose}
        TransitionComponent={Transition}
        PaperProps={{
          sx: {
            border: "1px solid white",
            p: "20px",
            boxShadow: "none",
            ml: "auto",
            width: "390px",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "white",
            borderColor: "white",
            pb: "20px",
            gap: "18px",
            overflow: "hidden",
          },
        }}
      >
        <AppBar
          sx={{
            position: "relative",
            background: "none",
            boxShadow: "none",
            borderRadius: 0,
          }}
        >
          <Toolbar
            disableGutters
            sx={{
              display: "flex",
              justifyContent: "space-between",
              bgcolor: "white",

              px: "16px",
              minHeight: "51px",
              [theme.breakpoints.up("sm")]: {
                minHeight: "51px",
              },
            }}
          >
            <IconButton
              disableRipple
              onClick={() => setIsDialogOpen(false)}
              aria-label="close"
              sx={{ p: 0, ml: "auto" }}
            >
              <CloseIcon sx={{ color: "black" }} />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box
          sx={{
            display: "flex",
            justifyContent: "right",
            flexDirection: "column",
            alignItems: "start",
          }}
        >
          <NavMenuItem to="/links">Мои ссылки</NavMenuItem>
          <Box sx={{ boxSizing: "border-box", ml: "-20px", width: "110%", bgcolor: isPopperOpen ? "#F2F3F7" : "none" }}>
            <NavMenuItem sx={{ pl: "28px" }} to="/create" onClick={handleClick}>
              Создание ссылки
            </NavMenuItem>
          </Box>

          {isPopperOpen && (
            <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "25px", ml: "40px", mt: "12px" }}>
              <MenuSubItem to="/create">Создание ссылки</MenuSubItem>
              <MenuSubItem to="/buy">Покупка и настройка custom ссылки</MenuSubItem>
              <MenuSubItem to="/">A/B тесты</MenuSubItem>
            </Box>
          )}
          <NavMenuItem to="/">Кастомизация ссылки</NavMenuItem>
          <NavMenuItem to="/stats">Статистика</NavMenuItem>
          <NavMenuItem to="/chart">Пиксель</NavMenuItem>
        </Box>
        <Box
          sx={{
            mt: "auto",
            ml: "auto",
            display: "flex",
            justifyContent: "center",
            px: "16px",
          }}
        >
          <LogoLight style={logoStyle} />
        </Box>
      </Dialog>
    </Box>
  );
}

function MenuSubItem({ children, to }: { to: string; children: ReactNode }) {
  return (
    <Box
      sx={{
        "&:hover .link": {
          color: "#7E2AEA",
        },
      }}
    >
      <Link className="link" to={to} style={{ color: "#4D4D4D", textDecoration: "none" }}>
        {children}
      </Link>
    </Box>
  );
}

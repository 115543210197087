import * as React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import CreateLink from "./pages/CreateLink/CreateLink";
import Stats from "./pages/Stats/Stats";
import Landing from "./pages/Landing/Landing";
import LinkTransitionsChart from "./pages/LinkTransitionsChart/LinkTransitionsChart";
import Buying from "./pages/Buying/Buying";
import MyLinks from "./pages/MyLinks/MyLinks";

import ScrollToTop from "./kitUI/ScrollToTop";
import Navbar from "./kitUI/Navbars/Navbar";

import dayjs from "dayjs";

import theme from "@theme";
import "dayjs/locale/ru";

dayjs.locale("ru");

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <ScrollToTop />
          <Navbar />
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/buy" element={<Buying />} />
            <Route path="/chart" element={<LinkTransitionsChart />} />
            <Route path="/links" element={<MyLinks />} />
            <Route path="/create" element={<CreateLink />} />
            <Route path="/stats" element={<Stats />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </LocalizationProvider>
  </React.StrictMode>
);

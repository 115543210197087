import { Box } from "@mui/material";


export default function StatisticsIcon() {

    return (
        <Box sx={{
            width: "24px",
            height: "24px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexShrink: 0,
        }}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3 21.333H20.5" stroke="#7E2AEA" strokeWidth="1.6" strokeLinecap="round" />
                <path d="M18.5 18V2" stroke="#7E2AEA" strokeWidth="1.6" strokeLinecap="round" />
                <path d="M14 18V6" stroke="#7E2AEA" strokeWidth="1.6" strokeLinecap="round" />
                <path d="M9.5 18V10" stroke="#7E2AEA" strokeWidth="1.6" strokeLinecap="round" />
                <path d="M5 14V18" stroke="#7E2AEA" strokeWidth="1.6" strokeLinecap="round" />
            </svg>
        </Box>
    );
}
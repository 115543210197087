import { Box } from "@mui/material";

export default function CalendarIcon() {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexShrink: 0,
        "&:hover path": {
          stroke: "#581CA7",
        },
        "&:active path": {
          stroke: "#FB5607",
        },
        "&:hover rect": {
          stroke: "#581CA7",
        },
        "&:active rect": {
          stroke: "#FB5607",
        },
      }}
    >
      <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1" y="2.5" width="18" height="18" rx="5" stroke="#7E2AEA" strokeWidth="1.5" />
        <path d="M1 7.5H19" stroke="#7E2AEA" strokeWidth="1.5" strokeLinejoin="round" />
        <path d="M14.5 1L14.5 4" stroke="#7E2AEA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.5 1L5.5 4" stroke="#7E2AEA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4.5 11.5H5.5" stroke="#7E2AEA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9.5 11.5H10.5" stroke="#7E2AEA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M14.5 11.5H15.5" stroke="#7E2AEA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4.5 15.5H5.5" stroke="#7E2AEA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9.5 15.5H10.5" stroke="#7E2AEA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M14.5 15.5H15.5" stroke="#7E2AEA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </Box>
  );
}

import Box from "@mui/material/Box";
import Logotip from "@kitUI/img/logo_pena_links_light";
import SectionStyled from "./section";
import { useTheme } from "@mui/material/styles";
import { Typography, useMediaQuery } from "@mui/material";
import NavMenuItem from "./Navbars/NavMenuItem";

export default function Footer() {
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <SectionStyled
      tag={"footer"}
      bg={"#252734"}
      mwidth={"1200px"}
      sxsect={{
        justifyContent: "space-around",
        position: "relative",
        zIndex: theme.zIndex.content,
        py: upMd ? "70px" : "40px",
      }}
      sxcont={{
        display: "flex",
        alignItems: "start",
        flexDirection: "column",
        boxSizing: "border-box",
        [theme.breakpoints.down("md")]: {
          padding: "0 18px",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: upMd ? "row" : "column",
          alignItems: "start",
          width: "100%",
          maxWidth: "702px",
          justifyContent: "space-between",
          columnGap: "30px",
          rowGap: "36px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Logotip style={{ minWidth: "124px", width: "124px", height: "48px" }} textColor="white" />
          <Typography
            sx={{
              color: "#727074",
              fontSize: "14px",
              lineHeight: "20px",
            }}
          >
            (c) 2023 Examplelink.com
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: upMd ? "column" : "column",
            alignItems: "start",
            flexWrap: "wrap",
            maxHeight: upMd ? "145px" : undefined,
            maxWidth: "450px",
            flexGrow: 1,
            ml: "-8px",
            mt: "-6px",
          }}
        >
          <NavMenuItem mode="dark" to="/links">
            Мои ссылки
          </NavMenuItem>
          <NavMenuItem mode="dark" to="/create">
            Создание ссылки
          </NavMenuItem>
          <NavMenuItem mode="dark" to="/stats">
            Статистика
          </NavMenuItem>
          <NavMenuItem mode="dark" to="/chart">
            Пиксель
          </NavMenuItem>
        </Box>
      </Box>
    </SectionStyled>
  );
}

import { Box } from "@mui/material";

export default function CopyIcon() {
  return (
    <Box
      sx={{
        width: "24px",
        height: "24px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexShrink: 0,
        "&:hover path": {
          stroke: "#581CA7",
        },
        "&:active path": {
          stroke: "#FB5607",
        },
      }}
    >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16.5357 17.4286V19.4643C16.5357 20.5886 15.6243 21.5 14.5 21.5H7.03571C5.91142 21.5 5 20.5886 5 19.4643V8.60714C5 7.48285 5.91142 6.57143 7.03571 6.57143H9.07143M16.5357 17.4286H11.1071C9.98285 17.4286 9.07143 16.5172 9.07143 15.3929V6.57143M16.5357 17.4286H17.2143C18.3386 17.4286 19.25 16.5172 19.25 15.3929V7.92857M13.8214 2.5H11.1071C9.98285 2.5 9.07143 3.41142 9.07143 4.53572V6.57143M13.8214 2.5V7.92857H19.25M13.8214 2.5L19.25 7.92857"
          stroke="#7E2AEA"
          strokeWidth="1.58333"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
}

import { forwardRef, useState } from "react";
import { TransitionProps } from "@mui/material/transitions";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import { AppBar, Dialog, IconButton, Slide, Toolbar } from "@mui/material";

import CloseIcon from "@root/kitUI/Icons/CloseIcon";
import BurgerIcon from "@root/kitUI/Icons/BurgerIcon";
import SectionStyled from "@kitUI/section";
import LogoLight from "@kitUI/img/logo_pena_links_light";

import NavMenuItem from "../NavMenuItem";

interface Props {
  menuItems: { path: string; title: string }[];
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const logoStyle = { minWidth: "101px", width: "101px", height: "39px" };

export default function TabletLandingNavbar({ menuItems }: Props) {
  const themeMUI = useTheme();
  const theme = useTheme();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <SectionStyled
      tag="header"
      mwidth="1200px"
      sxsect={{
        minHeight: "90px",
          position: "fixed",
        zIndex: themeMUI.zIndex.header,
          background: "linear-gradient(90deg, rgba(51,54,64,1) 0%, rgba(37,39,48,1) 100%)",
        top: 0,
        borderBottom: "1px solid #434654",
      }}
      sxcont={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <LogoLight style={{ minWidth: "150px" }} textColor="white" />
      <Box
        sx={{
          ml: "auto",
          display: "flex",
          gap: "20px",
        }}
      >
        <Button
          variant="outlined"
          disableRipple
          sx={{
            color: "white",
            border: "1px solid white",
            textTransform: "none",
            fontWeight: "400",
            fontSize: "18px",
            lineHeight: "24px",
            borderRadius: "8px",
            p: "9px 31px",
            "&:hover": {
              borderColor: "#7E2AEA",
              bgColor: "#7E2AEA",
            },
          }}
        >
          Регистрация
        </Button>
        <Button
          disableRipple
          variant="template"
          sx={{
            p: "9px 31px",
            "&:hover": {
              border: "1px solid white",
              bgcolor: "#7E2AEA",
            },
            "&:active": {
              borderColor: "#7E2AEA",
              bgcolor: "#7E2AEA",
              color: "white",
            },
          }}
        >
          Войти
        </Button>
        <IconButton
          disableRipple
          onClick={() => setIsDialogOpen(true)}
          sx={{
            p: 0,
          }}
        >
          <BurgerIcon sx={{ color: "white" }} />
        </IconButton>
        <Dialog
          fullScreen
          open={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          TransitionComponent={Transition}
          PaperProps={{
            sx: {
              p: "20px",
              boxShadow: "none",
              ml: "auto",
              width: "320px",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              background: "#252734",
              pb: "20px",
              gap: "18px",
            },
          }}
        >
          <AppBar
            sx={{
              position: "relative",
              boxShadow: "none",
              borderRadius: 0,
              background: "#252734",
            }}
          >
            <Toolbar
              disableGutters
              sx={{
                display: "flex",
                justifyContent: "space-between",
                px: "16px",
                minHeight: "51px",
                [theme.breakpoints.up("sm")]: {
                  minHeight: "51px",
                },
              }}
            >
              <IconButton
                disableRipple
                onClick={() => setIsDialogOpen(false)}
                aria-label="close"
                sx={{ p: 0, ml: "auto" }}
              >
                <CloseIcon sx={{ color: "white" }} />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Box
            sx={{
              display: "flex",
              justifyContent: "right",
              flexDirection: "column",
              alignItems: "end",
            }}
          >
            {menuItems.map((element) => (
              <NavMenuItem key={element.title} mode="dark" to={element.path}>
                {element.title}
              </NavMenuItem>
            ))}
          </Box>
          <Box
            sx={{
              mt: "auto",
              ml: "auto",
              display: "flex",
              justifyContent: "center",
              px: "16px",
            }}
          >
            <LogoLight style={logoStyle} textColor="white" />
          </Box>
        </Dialog>
      </Box>
    </SectionStyled>
  );
}

import { Box, Pagination, Paper, SxProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, useMediaQuery, useTheme } from "@mui/material";
import { useState } from "react";
import belarusImage from "@kitUI/img/countries/belarus.png";
import brazilImage from "@kitUI/img/countries/brazil.png";
import canadaImage from "@kitUI/img/countries/canada.png";
import chinaImage from "@kitUI/img/countries/china.png";
import croatiaImage from "@kitUI/img/countries/croatia.png";
import greeceImage from "@kitUI/img/countries/greece.png";
import italyImage from "@kitUI/img/countries/italy.png";
import kazakhstanImage from "@kitUI/img/countries/kazakhstan.png";
import latviaImage from "@kitUI/img/countries/latvia.png";
import russiaImage from "@kitUI/img/countries/russia.png";
import ukraineImage from "@kitUI/img/countries/ukraine.png";

const countryImage = {
    "belarus": belarusImage,
    "brazil": brazilImage,
    "canada": canadaImage,
    "china": chinaImage,
    "croatia": croatiaImage,
    "greece": greeceImage,
    "italy": italyImage,
    "kazakhstan": kazakhstanImage,
    "latvia": latviaImage,
    "russia": russiaImage,
    "ukraine": ukraineImage,
} as const;

type Country = keyof typeof countryImage;

function createData(
    date: string,
    transitions: number,
    uniqueTransitions: number,
    countryCodes: Country[],
    cities: string[],
) {
    return {
        id: Math.random().toString(),
        date,
        transitions,
        uniqueTransitions,
        countryCodes,
        cities,
    };
}

const rows = [
    createData("09.02.2023", 12, 3, ["italy", "croatia", "russia", "kazakhstan"], ["Александровск-Сахалинский"]),
    createData("09.02.2023", 9, 0, ["russia", "italy"], ["Екатеринбург"]),
    createData("09.02.2023", 34, 1, ["ukraine", "brazil", "greece"], ["Москва"]),
    createData("10.02.2023", 10, 11, ["belarus", "brazil", "greece", "ukraine", "canada", "kazakhstan", "latvia", "china", "russia", "italy"], ["Южно-Сахалинск"]),
];

interface Props {
    sx?: SxProps<Theme>;
}

export default function LinkStatTable({ sx }: Props) {
    const theme = useTheme();
    const upMd = useMediaQuery(theme.breakpoints.up("md"));
    const [currentPage, setCurrentPage] = useState<number>(0);

    return (
        <Box sx={{
            ...sx,
        }}>
            <TableContainer component={Paper}>
                <Table
                    sx={{
                        tableLayout: "fixed",
                        minWidth: "400px",
                    }}
                    aria-label="simple table"
                >
                    <TableHead>
                        <TableRow sx={{
                            backgroundColor: "#9A9AAF",
                            "& th": {
                                color: "white",
                                fontSize: "16px",
                                lineHeight: "20px",
                                pt: "20px",
                                overflow: "hidden",
                            }
                        }}>
                            <TableCell align="center" sx={{ width: "136px" }}>Дата</TableCell>
                            <TableCell align="center" sx={{ width: "121px" }}>Переходы</TableCell>
                            <TableCell align="center" sx={{ width: "121px" }}>Уникальные переходы</TableCell>
                            <TableCell align="center" sx={{ width: "261px" }}>Страны</TableCell>
                            <TableCell align="center" sx={{ width: "299px" }}>Города</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow
                                key={`${row.date}-${row.countryCodes.toString()}`}
                                sx={{
                                    "&:nth-of-type(even)": {
                                        backgroundColor: "#F2F3F7",
                                    },
                                    "& td": {
                                        fontWeight: 400,
                                        fontSize: "18px",
                                        lineHeight: "21px",
                                        color: "black",
                                        border: 0,
                                        py: "25px",
                                        overflow: "hidden",
                                    },
                                    "& td:not(:last-of-type)": {
                                        borderRight: "1px solid #9A9AAF",
                                    }
                                }}
                            >
                                <TableCell align="center">{row.date}</TableCell>
                                <TableCell align="center">{row.transitions}</TableCell>
                                <TableCell align="center">{row.uniqueTransitions}</TableCell>
                                <TableCell align="center" sx={{ px: "40px", py: "9px !important" }}>
                                    {row.countryCodes.map(code =>
                                        <img key={code} src={countryImage[code]} alt={`${code} flag`} />
                                    )}
                                </TableCell>
                                <TableCell align="center">{row.cities.join(",")}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box sx={{
                mt: "40px",
                display: "flex",
                justifyContent: upMd ? "end" : "center",
            }}>
                <Pagination
                    shape="rounded"
                    count={5}
                    page={currentPage + 1}
                    onChange={(e, value) => setCurrentPage(value - 1)}
                />
            </Box>
        </Box >
    );
}
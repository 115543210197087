import { Point } from "chart.js";


const millisecondsPerDay = 1000 * 3600 * 24;

export default function createChartMockData(numberOfPoints: number): Point[] {
    const chartData: Point[] = [];
    let timestamp = new Date(2021, 9, 1).getTime();
    let value = Math.random() * 20;

    for (let i = 0; i < numberOfPoints; i++) {
        chartData.push({
            x: timestamp += millisecondsPerDay,
            y: Math.round(value),
        });
        value += Math.random() * 3 - 1.4;
        value = Math.max(0, value);
    }

    return chartData;
}
import { createTheme } from "@mui/material";
declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    template: true;
    underlined: true;
  }
}

declare module "@mui/material/styles/zIndex.d" {
  interface ZIndex {
    bubbles: number;
    content: number;
    header: number;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    t1: true;
  }
}

const theme = createTheme({
  palette: {
    background: {
      default: "#F2F3F7",
    },
  },
  breakpoints: {
    values: {
      xs: 460,
      sm: 600,
      md: 680,
      lg: 900,
      xl: 1000,
    },
  },
  typography: {
    fontFamily: "Rubik",
  },

  components: {
    MuiButton: {
      variants: [
        {
          props: {
            variant: "template",
          },
          style: {
            background: "#7E2AEA",
            border: "1px solid #7E2AEA",
            borderRadius: "8px",
            padding: "15px 56px",
            textTransform: "none",
            color: "#ffffff",
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "24px",
          },
        },
        {
          props: {
            variant: "underlined",
          },
          style: {
            color: "#7E2AEA",
            padding: 0,
            textTransform: "none",
            textDecoration: "underline",
            textUnderlineOffset: "7px",
            fontSize: "16px",
            lineHeight: "20px",
          },
        },
      ],
    },
    MuiTypography: {
      variants: [
        {
          props: {
            variant: "h1",
          },
          style: {
            color: "#000000",
            fontWeight: 500,
            fontSize: "70px",
            lineHeight: "100%",
          },
        },
        {
          props: {
            variant: "h3",
          },
          style: {
            color: "#000000",
            fontWeight: 500,
            fontSize: "36px",
            lineHeight: "100%",
          },
        },
        {
          props: {
            variant: "h4",
          },
          style: {
            color: "#000000",
            fontWeight: 500,
            fontSize: "24px",
            lineHeight: "100%",
          },
        },
        {
          props: {
            variant: "t1",
          },
          style: {
            display: "block",
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "21.33px",
          },
        },
      ],
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
          borderRadius: "12px",
          boxShadow: `
                        0px 100px 309px rgba(210, 208, 225, 0.24), 
                        0px 41.7776px 129.093px rgba(210, 208, 225, 0.172525), 
                        0px 22.3363px 69.0192px rgba(210, 208, 225, 0.143066), 
                        0px 12.5216px 38.6916px rgba(210, 208, 225, 0.12), 
                        0px 6.6501px 20.5488px rgba(210, 208, 225, 0.0969343), 
                        0px 2.76726px 8.55082px rgba(210, 208, 225, 0.0674749)
                    `,
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          color: "#7E2AEA",
          height: "50px",
          width: "69px",
          "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
            backgroundColor: "#7E2AEA",
            opacity: 1,
          },
        },
        track: {
          height: "12px",
          alignSelf: "center",
          backgroundColor: "#00000000",
          opacity: 1,
          border: "1px solid #9A9AAF",
        },
        thumb: {
          height: "32px",
          width: "32px",
          border: `6px solid #7E2AEA`,
          backgroundColor: "white",
          boxShadow: `0px 0px 0px 3px white,
                                0px 4px 4px 3px #C3C8DD
                    `,

          "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
            boxShadow: `0px 0px 0px 3px white,
                                    0px 4px 4px 3px #C3C8DD
                        `,
          },
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          marginRight: "-15px",
          marginLeft: "-15px",
          "& .MuiPaginationItem-root": {
            height: "30px",
            width: "30px",
            minWidth: "30px",
            marginLeft: "5px",
            marginRight: "5px",
            backgroundColor: "white",
            color: "black",
            fontSize: "16px",
            lineHeight: "20px",
            fontWeight: 400,
            borderRadius: "5px",
            "&.Mui-selected": {
              backgroundColor: "white",
              color: "#7E2AEA",
              fontWeight: 500,
            },
            "&:hover": {
              backgroundColor: "#ffffff55",
            },
            "&:active": {
              backgroundColor: "#7F2CEA",
              color: "white",
            },

            boxShadow: `
                            0px 77.2727px 238.773px rgba(210, 208, 225, 0.24), 
                            0px 32.2827px 99.7535px rgba(210, 208, 225, 0.172525), 
                            0px 17.2599px 53.333px rgba(210, 208, 225, 0.143066), 
                            0px 9.67574px 29.8981px rgba(210, 208, 225, 0.12), 
                            0px 5.13872px 15.8786px rgba(210, 208, 225, 0.0969343), 
                            0px 2.13833px 6.60745px rgba(210, 208, 225, 0.0674749)
                        `,
          },
          "& .MuiPaginationItem-previousNext": {
            backgroundColor: "#7E2AEA",
            color: "white",
            marginLeft: "15px",
            marginRight: "15px",
            "&:hover": {
              backgroundColor: "#995DED",
            },
          },
        },
      },
    },
  },
  zIndex: {
    bubbles: 0,
    content: 1,
    header: 10,
  },
});

theme.typography.h1 = {
  [theme.breakpoints.down("md")]: {
    fontSize: "36px",
    lineHeight: "100%",
  },
};

theme.typography.h3 = {
  [theme.breakpoints.down("md")]: {
    fontSize: "30px",
    lineHeight: "100%",
  },
};

theme.typography.h4 = {
  [theme.breakpoints.down("md")]: {
    fontSize: "21px",
    lineHeight: "100%",
  },
};

export default theme;

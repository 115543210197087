import { ReactNode, useState } from "react";
import { Typography, Box, Button, Paper, useMediaQuery, useTheme } from "@mui/material";

import CustomTextField from "@root/kitUI/CustomTextField";

interface Props {
  id: string;
  icon: ReactNode;
  headerText: string;
}

export default function LinkGenerationCard({ id, headerText, icon }: Props) {
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up("md"));
  const [linkToShortenField, setLinkToShortenField] = useState<string>("");
  const [shortenedLink, setShortenedLink] = useState<string>("");

  return (
    <Paper
      sx={{
        width: "100%",
        p: "20px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "14px",
          alignItems: "center",
        }}
      >
        {icon}
        <Typography variant="h4" lineHeight="100% !important">
          {headerText}
        </Typography>
      </Box>
      <CustomTextField
        id={`${id}-input`}
        label="Ссылка которую вы хотите сократить"
        placeholder="Введите ссылку"
        backgroundColor="#F2F3F7"
        value={linkToShortenField}
        onChange={(e) => setLinkToShortenField(e.target.value)}
        sx={{
          mt: upMd ? "29px" : "23px",
        }}
      />
      <CustomTextField
        id={`${id}-generated`}
        label="Генерация сокращенной ссылки"
        placeholder="Здесь будет ваша новая ссылка вида links.ru/xxx"
        value={shortenedLink}
        disabled
        sx={{
          mt: upMd ? "23px" : "21px",
        }}
      />
      <Box
        sx={{
          mt: "30px",
          display: "flex",
          flexWrap: "wrap",
          gap: upMd ? "20px" : "13px",
          maxWidth: "412px",
        }}
      >
        <Button
          disableRipple
          variant="template"
          sx={{
            p: "9px 0",
            minWidth: "min-content",
            flexGrow: 1,
            flexBasis: 0,
            "&:hover": {
              borderColor: "#944FEE",
              bgcolor: "#944FEE",
            },
            "&:active": {
              borderColor: "#944FEE",
              color: "#944FEE",
              bgcolor: "white",
            },
          }}
        >
          Сгенерировать
        </Button>
        <Button
          disableRipple
          variant="template"
          disabled={linkToShortenField === "" ? true : false}
          sx={{
            p: "9px 0",
            minWidth: "min-content",
            flexGrow: 1,
            flexBasis: 0,
            borderColor: linkToShortenField === "" ? "#9A9AAF" : "#944FEE",
            color: "#944FEE",
            bgcolor: "white",
            "&:hover": {
              bgcolor: "white",
            },
            "&:active": {
              bgcolor: "#581CA7",
              color: "white",
            },
          }}
        >
          Сохранить
        </Button>
      </Box>
    </Paper>
  );
}

import { useMediaQuery, useTheme } from "@mui/material";

import DesktopLandingNavbar from "./DesktopLandingNavbar";
import MobileLandingNavbar from "./MobileLandingNavbar";
import TabletLandingNavbar from "./TabletLandingNavbar";

const landingNavMenuItems: { path: string; title: string }[] = [
  { path: "/links", title: "Мои ссылки" },
  { path: "/create", title: "Создание ссылки" },
  { path: "/stats", title: "Статистика" },
  { path: "/chart", title: "Пиксель" },
];

export default function LandingNavbar() {
  const muiTheme = useTheme();
  const phablet = useMediaQuery(muiTheme.breakpoints.up(1000));
  const isMobile = useMediaQuery(muiTheme.breakpoints.up(600));

  return phablet ? (
    <DesktopLandingNavbar menuItems={landingNavMenuItems} />
  ) : isMobile ? (
    <TabletLandingNavbar menuItems={landingNavMenuItems} />
  ) : (
    <MobileLandingNavbar menuItems={landingNavMenuItems} />
  );
}

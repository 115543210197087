import { Typography, Box } from "@mui/material";


interface Props {
    number: string;
    headerText: string;
    text: string;
}

export default function BigNumberTextCard({ headerText, number, text }: Props) {

    return (
        <Box sx={{
            display: "flex",
            gap: "28px",
        }}>
            <Typography sx={{
                color: "#7E2AEA",
                fontWeight: 500,
                fontSize: "50px",
                lineHeight: "59px",
                width: "0.64em",
                flexShrink: 0,
            }}>{number}</Typography>
            <Box>
                <Typography sx={{
                    fontWeight: 500,
                    fontSize: "24px",
                    lineHeight: "28px",
                    color: "white",
                    mt: "6px",
                }}>{headerText}</Typography>
                <Typography color="white" variant="t1" mt="10px">{text}</Typography>
            </Box>
        </Box>
    );
}
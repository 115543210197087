import { Box } from "@mui/material";

export default function EditIcon() {
  return (
    <Box
      sx={{
        width: "44px",
        height: "44px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexShrink: 0,
        "&:hover": {
          bgcolor: "#995DED",
          borderRadius: "8px",
        },
        "&:hover path": {
          fill: "white",
        },
      }}
    >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.5137 3.80583C14.5869 2.73107 16.3274 2.73041 17.4013 3.80435L19.8932 6.29621C20.958 7.36099 20.969 9.08475 19.918 10.1631L10.6849 19.6364C9.97933 20.3603 9.01167 20.7684 8.00124 20.7684L5.24909 20.7683C3.96984 20.7682 2.94823 19.7018 3.00203 18.4228L3.12019 15.6137C3.15968 14.6747 3.54996 13.7847 4.2138 13.1198L13.5137 3.80583ZM16.3415 4.86582C15.8533 4.37766 15.0622 4.37796 14.5744 4.86649L12.9113 6.53209L17.1911 10.8119L18.8446 9.11536C19.3224 8.62519 19.3173 7.84166 18.8333 7.35767L16.3415 4.86582ZM5.27446 14.1805L11.8514 7.59355L16.144 11.8862L9.61148 18.5886C9.18816 19.0229 8.60756 19.2678 8.0013 19.2678L5.24916 19.2677C4.82274 19.2677 4.4822 18.9122 4.50014 18.4859L4.61829 15.6768C4.64199 15.1134 4.87616 14.5794 5.27446 14.1805ZM20.5148 20.6951C20.9289 20.6951 21.2645 20.3592 21.2645 19.9448C21.2645 19.5304 20.9289 19.1945 20.5148 19.1945H14.3931C13.9791 19.1945 13.6434 19.5304 13.6434 19.9448C13.6434 20.3592 13.9791 20.6951 14.3931 20.6951H20.5148Z"
          fill="#7E2AEA"
        />
      </svg>
    </Box>
  );
}

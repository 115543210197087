

interface Props {
    lineColor: string;
    bgcolor: string;
}

export default function CheckIcon({ bgcolor, lineColor }: Props) {

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.533325 7.99987C0.533325 3.87614 3.87627 0.533203 7.99999 0.533203C12.1237 0.533203 15.4667 3.87614 15.4667 7.99987C15.4667 12.1236 12.1237 15.4665 7.99999 15.4665C3.87627 15.4665 0.533325 12.1236 0.533325 7.99987Z" fill={bgcolor} />
            <path d="M4.26666 7.99987L7.46666 10.6665L11.7333 5.3332M7.99999 15.4665C3.87627 15.4665 0.533325 12.1236 0.533325 7.99987C0.533325 3.87614 3.87627 0.533203 7.99999 0.533203C12.1237 0.533203 15.4667 3.87614 15.4667 7.99987C15.4667 12.1236 12.1237 15.4665 7.99999 15.4665Z" stroke={bgcolor} strokeLinecap="round" />
            <path d="M4.26562 8.00065L7.46562 10.6673L11.7323 5.33398" stroke={lineColor} strokeLinecap="round" />
        </svg>
    );
}
import { useMediaQuery, useTheme } from "@mui/material";
import { CSSProperties } from "react";


interface Props {
    style?: CSSProperties;
}

export default function ChainLinks({ style }: Props) {
    const theme = useTheme();
    const upLg = useMediaQuery(theme.breakpoints.up("lg"));

    const viewBox = upLg ? "0 0 578 401" : "146 0 438 381";
    const width = upLg ? "578" : "501";

    return (
        <svg width={width} height="401" viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
            <g filter="url(#filter0_f_522_454)">
                <path fillRule="evenodd" clipRule="evenodd" d="M525.412 105.828L138.543 106.977L138.543 108.126L525.412 109.275C525.412 109.275 528.062 109.275 528.062 107.552C528.062 105.828 525.412 105.828 525.412 105.828Z" fill="url(#paint0_linear_522_454)" />
            </g>
            <g filter="url(#filter1_f_522_454)">
                <circle cx="3.41645" cy="3.41645" r="3.41645" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 532.766 110.766)" fill="#9B4FFD" />
                <circle cx="3.41645" cy="3.41645" r="3.41645" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 532.766 110.766)" fill="url(#paint1_radial_522_454)" />
            </g>
            <g filter="url(#filter2_f_522_454)">
                <path fillRule="evenodd" clipRule="evenodd" d="M566.068 325.789L293.786 327.102L293.786 328.416L566.068 329.729C566.068 329.729 567.933 329.729 567.933 327.759C567.933 325.789 566.068 325.789 566.068 325.789Z" fill="url(#paint2_linear_522_454)" />
            </g>
            <g filter="url(#filter3_f_522_454)">
                <circle cx="3.71952" cy="3.71952" r="3.71952" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 572.997 331.534)" fill="#9B4FFD" />
                <circle cx="3.71952" cy="3.71952" r="3.71952" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 572.997 331.534)" fill="url(#paint3_radial_522_454)" />
            </g>
            <g opacity="0.4">
                <g filter="url(#filter4_f_522_454)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M397.089 32.566L64.5546 33.7048L64.5546 34.8436L397.089 35.9824C397.089 35.9824 399.366 35.9824 399.366 34.2742C399.366 32.566 397.089 32.566 397.089 32.566Z" fill="url(#paint4_linear_522_454)" />
                </g>
                <g filter="url(#filter5_f_522_454)">
                    <circle cx="3.41645" cy="3.41645" r="3.41645" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 403.921 37.6934)" fill="#9B4FFD" />
                    <circle cx="3.41645" cy="3.41645" r="3.41645" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 403.921 37.6934)" fill="url(#paint5_radial_522_454)" />
                </g>
            </g>
            <g opacity="0.4">
                <g filter="url(#filter6_f_522_454)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M344.073 352.292L136.944 353.441L136.944 354.59L344.073 355.739C344.073 355.739 345.492 355.739 345.492 354.016C345.492 352.292 344.073 352.292 344.073 352.292Z" fill="url(#paint6_linear_522_454)" />
                </g>
                <g filter="url(#filter7_f_522_454)">
                    <circle cx="3.41645" cy="3.41645" r="3.41645" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 349.634 357.405)" fill="#9B4FFD" />
                    <circle cx="3.41645" cy="3.41645" r="3.41645" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 349.634 357.405)" fill="url(#paint7_radial_522_454)" />
                </g>
            </g>
            <g opacity="0.15">
                <g filter="url(#filter8_f_522_454)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M239.975 173.047L59.3788 174.196L59.3788 175.345L239.975 176.494C239.975 176.494 241.212 176.494 241.212 174.771C241.212 173.047 239.975 173.047 239.975 173.047Z" fill="url(#paint8_linear_522_454)" />
                </g>
                <g filter="url(#filter9_f_522_454)">
                    <circle cx="3.41645" cy="3.41645" r="3.41645" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 245.353 178.156)" fill="#9B4FFD" />
                    <circle cx="3.41645" cy="3.41645" r="3.41645" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 245.353 178.156)" fill="url(#paint9_radial_522_454)" />
                </g>
            </g>
            <g opacity="0.15">
                <g filter="url(#filter10_f_522_454)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M502.819 148.914L322.223 150.063L322.223 151.212L502.819 152.361C502.819 152.361 504.056 152.361 504.056 150.638C504.056 148.914 502.819 148.914 502.819 148.914Z" fill="url(#paint10_linear_522_454)" />
                </g>
                <g filter="url(#filter11_f_522_454)">
                    <circle cx="3.41645" cy="3.41645" r="3.41645" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 508.197 154.023)" fill="#9B4FFD" />
                    <circle cx="3.41645" cy="3.41645" r="3.41645" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 508.197 154.023)" fill="url(#paint11_radial_522_454)" />
                </g>
            </g>
            <g opacity="0.15">
                <g filter="url(#filter12_f_522_454)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M183.1 6.72286L2.50398 7.87187L2.50398 9.02089L183.1 10.1699C183.1 10.1699 184.337 10.1699 184.337 8.44639C184.337 6.72286 183.1 6.72286 183.1 6.72286Z" fill="url(#paint12_linear_522_454)" />
                </g>
                <g filter="url(#filter13_f_522_454)">
                    <circle cx="3.41645" cy="3.41645" r="3.41645" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 188.479 11.832)" fill="#9B4FFD" />
                    <circle cx="3.41645" cy="3.41645" r="3.41645" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 188.479 11.832)" fill="url(#paint13_radial_522_454)" />
                </g>
            </g>
            <g opacity="0.15">
                <g filter="url(#filter14_f_522_454)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M519.25 390.892L338.654 392.041L338.654 393.19L519.25 394.339C519.25 394.339 520.487 394.339 520.487 392.615C520.487 390.892 519.25 390.892 519.25 390.892Z" fill="url(#paint14_linear_522_454)" />
                </g>
                <g filter="url(#filter15_f_522_454)">
                    <circle cx="3.41645" cy="3.41645" r="3.41645" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 524.629 396.001)" fill="#9B4FFD" />
                    <circle cx="3.41645" cy="3.41645" r="3.41645" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 524.629 396.001)" fill="url(#paint15_radial_522_454)" />
                </g>
            </g>
            <g opacity="0.4">
                <g filter="url(#filter16_f_522_454)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M458.276 258.343L125.742 259.482L125.742 260.621L458.276 261.76C458.276 261.76 460.554 261.76 460.554 260.052C460.554 258.343 458.276 258.343 458.276 258.343Z" fill="url(#paint16_linear_522_454)" />
                </g>
                <g filter="url(#filter17_f_522_454)">
                    <circle cx="3.41645" cy="3.41645" r="3.41645" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 465.108 263.471)" fill="#9B4FFD" />
                    <circle cx="3.41645" cy="3.41645" r="3.41645" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 465.108 263.471)" fill="url(#paint17_radial_522_454)" />
                </g>
            </g>
            <g opacity="0.4">
                <g filter="url(#filter18_f_522_454)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M397.186 198.64L64.652 199.779L64.652 200.918L397.186 202.057C397.186 202.057 399.464 202.057 399.464 200.348C399.464 198.64 397.186 198.64 397.186 198.64Z" fill="url(#paint18_linear_522_454)" />
                </g>
                <g filter="url(#filter19_f_522_454)">
                    <circle cx="3.41645" cy="3.41645" r="3.41645" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 404.018 203.768)" fill="#9B4FFD" />
                    <circle cx="3.41645" cy="3.41645" r="3.41645" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 404.018 203.768)" fill="url(#paint19_radial_522_454)" />
                </g>
            </g>
            <g opacity="0.8">
                <path d="M190.931 117C190.849 117 190.746 116.968 190.623 116.904C190.499 116.839 190.397 116.786 190.315 116.743L179.894 109.316C179.586 109.102 179.36 108.888 179.216 108.673C179.072 108.459 179 108.202 179 107.902V107.098C179 106.798 179.072 106.541 179.216 106.327C179.36 106.112 179.586 105.898 179.894 105.684L190.315 98.2572C190.397 98.2143 190.499 98.1607 190.623 98.0964C190.746 98.0321 190.849 98 190.931 98C191.096 98 191.239 98.0643 191.363 98.1929C191.486 98.3001 191.548 98.4501 191.548 98.643V101.086C191.548 101.386 191.476 101.622 191.332 101.794C191.188 101.965 191.003 102.115 190.777 102.244L183.285 107.484L190.777 112.756C191.003 112.906 191.188 113.067 191.332 113.239C191.476 113.389 191.548 113.614 191.548 113.914V116.357C191.548 116.55 191.486 116.711 191.363 116.839C191.239 116.946 191.096 117 190.931 117Z" fill="white" />
                <path d="M203.383 117C203.301 117 203.198 116.968 203.075 116.904C202.952 116.839 202.849 116.786 202.767 116.743L192.346 109.316C192.038 109.102 191.812 108.888 191.668 108.673C191.524 108.459 191.452 108.202 191.452 107.902V107.098C191.452 106.798 191.524 106.541 191.668 106.327C191.812 106.112 192.038 105.898 192.346 105.684L202.767 98.2572C202.849 98.2143 202.952 98.1607 203.075 98.0964C203.198 98.0321 203.301 98 203.383 98C203.548 98 203.692 98.0643 203.815 98.1929C203.938 98.3001 204 98.4501 204 98.643V101.086C204 101.386 203.928 101.622 203.784 101.794C203.64 101.965 203.455 102.115 203.229 102.244L195.738 107.484L203.229 112.756C203.455 112.906 203.64 113.067 203.784 113.239C203.928 113.389 204 113.614 204 113.914V116.357C204 116.55 203.938 116.711 203.815 116.839C203.692 116.946 203.548 117 203.383 117Z" fill="white" />
            </g>
            <g opacity="0.8">
                <path d="M499.237 347.121C499.413 347.121 499.632 347.056 499.896 346.924C500.16 346.793 500.379 346.684 500.555 346.596L522.825 331.439C523.484 331.001 523.967 330.564 524.275 330.127C524.582 329.689 524.736 329.164 524.736 328.552V326.911C524.736 326.299 524.582 325.774 524.275 325.337C523.967 324.899 523.484 324.462 522.825 324.024L500.555 308.867C500.379 308.779 500.16 308.67 499.896 308.539C499.632 308.407 499.413 308.342 499.237 308.342C498.886 308.342 498.578 308.473 498.315 308.735C498.051 308.954 497.919 309.26 497.919 309.654V314.641C497.919 315.253 498.073 315.735 498.381 316.085C498.688 316.435 499.083 316.741 499.567 317.003L515.578 327.699L499.567 338.46C499.083 338.766 498.688 339.094 498.381 339.444C498.073 339.75 497.919 340.21 497.919 340.822V345.809C497.919 346.203 498.051 346.531 498.315 346.793C498.578 347.012 498.886 347.121 499.237 347.121Z" fill="white" />
                <path d="M472.624 347.121C472.8 347.121 473.02 347.056 473.283 346.924C473.547 346.793 473.766 346.684 473.942 346.596L496.212 331.439C496.871 331.001 497.355 330.564 497.662 330.127C497.969 329.689 498.123 329.164 498.123 328.552V326.911C498.123 326.299 497.969 325.774 497.662 325.337C497.355 324.899 496.871 324.462 496.212 324.024L473.942 308.867C473.766 308.779 473.547 308.67 473.283 308.539C473.02 308.407 472.8 308.342 472.624 308.342C472.273 308.342 471.965 308.473 471.702 308.735C471.438 308.954 471.307 309.26 471.307 309.654V314.641C471.307 315.253 471.46 315.735 471.768 316.085C472.075 316.435 472.471 316.741 472.954 317.003L488.965 327.699L472.954 338.46C472.471 338.766 472.075 339.094 471.768 339.444C471.46 339.75 471.307 340.21 471.307 340.822V345.809C471.307 346.203 471.438 346.531 471.702 346.793C471.965 347.012 472.273 347.121 472.624 347.121Z" fill="white" />
            </g>
            <path d="M390.565 73.635C401.539 62.6611 416.903 57.7229 431.169 58.2716C445.435 58.8202 455.86 61.0168 466.834 70.3446C477.808 79.6725 486.587 92.8412 487.684 108.205C488.443 118.83 488.153 129.981 483 141.112C480.702 146.075 477.438 151.034 472.87 155.941C458.055 171.853 430.62 197.642 421.292 206.97C411.964 216.298 396.601 221.236 382.884 221.236C369.166 221.236 353.254 216.298 341.183 203.678C337.368 199.863 336.692 195.107 337.392 191.004C338.001 187.435 339.651 184.359 341.183 182.827C344.475 179.535 351.059 175.146 360.935 180.084C364.776 181.73 368.068 188.863 377.396 189.96C386.724 191.058 394.954 189.412 400.441 184.473C405.928 179.535 444.337 140.578 449.824 135.091C452.647 132.268 455.179 128.138 456.45 123C457.649 118.151 457.724 112.404 455.86 106.01C452.019 92.8412 437.204 88.4516 430.62 88.4516C424.035 88.4516 416.902 91.7438 411.964 96.1333C408.013 99.645 389.102 118.447 380.14 127.409C373.556 124.847 356.437 120.492 340.634 123.566L344 120.2L390.565 73.635Z" fill="#7E2AEA" />
            <path d="M390.565 73.635C401.539 62.6611 416.903 57.7229 431.169 58.2716C445.435 58.8202 455.86 61.0168 466.834 70.3446C477.808 79.6725 486.587 92.8412 487.684 108.205C488.443 118.83 488.153 129.981 483 141.112C480.702 146.075 477.438 151.034 472.87 155.941C458.055 171.853 430.62 197.642 421.292 206.97C411.964 216.298 396.601 221.236 382.884 221.236C369.166 221.236 353.254 216.298 341.183 203.678C337.368 199.863 336.692 195.107 337.392 191.004C338.001 187.435 339.651 184.359 341.183 182.827C344.475 179.535 351.059 175.146 360.935 180.084C364.776 181.73 368.068 188.863 377.396 189.96C386.724 191.058 394.954 189.412 400.441 184.473C405.928 179.535 444.337 140.578 449.824 135.091C452.647 132.268 455.179 128.138 456.45 123C457.649 118.151 457.724 112.404 455.86 106.01C452.019 92.8412 437.204 88.4516 430.62 88.4516C424.035 88.4516 416.902 91.7438 411.964 96.1333C408.013 99.645 389.102 118.447 380.14 127.409C373.556 124.847 356.437 120.492 340.634 123.566L344 120.2L390.565 73.635Z" fill="url(#paint20_linear_522_454)" fillOpacity="0.2" />
            <path d="M421.292 206.969C430.62 197.642 458.055 171.853 472.87 155.941C477.438 151.034 480.702 146.074 483 141.111C477.847 152.242 427.5 198.69 418.5 205.5C407.5 213.822 399.767 216.236 382.884 217.5C366 218.763 335.09 204.5 337.392 191.004C336.692 195.107 337.368 199.863 341.183 203.677C353.254 216.297 369.166 221.236 382.884 221.236C396.601 221.236 411.964 216.297 421.292 206.969Z" fill="url(#paint21_linear_522_454)" />
            <path d="M455.86 106.009C452.019 92.8408 437.204 88.4512 430.62 88.4512C424.035 88.4512 416.902 91.7434 411.964 96.133C408.013 99.6446 389.102 118.447 380.14 127.409C373.556 124.847 356.437 120.492 340.634 123.566L344 120.2C356.4 117.507 372.833 120.944 379.5 123C388.333 114.166 406.8 95.7996 410 92.9996C414 89.4996 421.739 84.4996 430.62 84.9996C439.5 85.4996 464.4 91.4996 456.45 123C457.649 118.15 457.724 112.403 455.86 106.009Z" fill="url(#paint22_linear_522_454)" />
            <path d="M396.363 231.662L346.432 281.593C335.458 292.567 320.095 297.506 305.829 296.957C296.327 296.592 288.529 295.495 281.138 292.047C277.432 290.319 273.828 287.999 270.163 284.884C259.189 275.556 250.41 262.387 249.313 247.024C248.216 231.66 249.313 215.2 264.128 199.287C278.942 183.375 306.377 157.586 315.705 148.259C325.033 138.931 340.396 133.993 354.114 133.993C367.831 133.993 383.743 138.931 395.815 151.551C402.948 158.684 399.107 169.109 395.815 172.401C393.942 174.274 391.004 176.502 387.001 177.166C383.967 177.669 380.321 177.274 376.062 175.145C372.221 173.499 368.929 166.366 359.601 165.268C350.273 164.171 342.043 165.817 336.556 170.755C331.069 175.693 292.66 214.651 287.173 220.138C281.686 225.625 277.297 236.05 281.138 249.219C282.133 252.632 283.866 255.455 286.001 257.765C292.105 264.368 301.5 266.777 306.378 266.777C312.962 266.777 320.095 263.485 325.033 259.095C328.984 255.584 347.896 236.782 356.858 227.82C362.564 230.04 376.184 233.607 390.001 232.517C392.127 232.35 394.257 232.072 396.363 231.662Z" fill="#7E2AEA" />
            <path d="M396.363 231.662L346.432 281.593C335.458 292.567 320.095 297.506 305.829 296.957C296.327 296.592 288.529 295.495 281.138 292.047C277.432 290.319 273.828 287.999 270.163 284.884C259.189 275.556 250.41 262.387 249.313 247.024C248.216 231.66 249.313 215.2 264.128 199.287C278.942 183.375 306.377 157.586 315.705 148.259C325.033 138.931 340.396 133.993 354.114 133.993C367.831 133.993 383.743 138.931 395.815 151.551C402.948 158.684 399.107 169.109 395.815 172.401C393.942 174.274 391.004 176.502 387.001 177.166C383.967 177.669 380.321 177.274 376.062 175.145C372.221 173.499 368.929 166.366 359.601 165.268C350.273 164.171 342.043 165.817 336.556 170.755C331.069 175.693 292.66 214.651 287.173 220.138C281.686 225.625 277.297 236.05 281.138 249.219C282.133 252.632 283.866 255.455 286.001 257.765C292.105 264.368 301.5 266.777 306.378 266.777C312.962 266.777 320.095 263.485 325.033 259.095C328.984 255.584 347.896 236.782 356.858 227.82C362.564 230.04 376.184 233.607 390.001 232.517C392.127 232.35 394.257 232.072 396.363 231.662Z" fill="url(#paint23_linear_522_454)" fillOpacity="0.2" />
            <path d="M346.432 281.595L396.363 231.664C394.257 232.074 392.127 232.352 390.001 232.519C378.501 242.539 346.001 278.5 333.501 286C321.001 293.5 294.501 298.283 281.138 292.049C288.529 295.497 296.327 296.593 305.828 296.959C320.095 297.508 335.458 292.569 346.432 281.595Z" fill="#AD76F3" />
            <path d="M346.432 281.595L396.363 231.664C394.257 232.074 392.127 232.352 390.001 232.519C378.501 242.539 346.001 278.5 333.501 286C321.001 293.5 294.501 298.283 281.138 292.049C288.529 295.497 296.327 296.593 305.828 296.959C320.095 297.508 335.458 292.569 346.432 281.595Z" fill="url(#paint24_linear_522_454)" />
            <path d="M359.601 165.27C368.929 166.368 372.221 173.501 376.062 175.147C380.321 177.276 383.967 177.671 387.001 177.168C382.5 177.221 378.061 173.243 376.062 170.5C373.246 166.636 369.001 162.5 360.501 161C352.001 159.5 341.501 161 334.001 167.5C326.501 174 290.501 211.5 285.001 217C279.501 222.5 267.501 240.534 286.001 257.767C283.866 255.457 282.133 252.634 281.138 249.221C277.297 236.052 281.686 225.627 287.173 220.14C292.66 214.653 331.069 175.695 336.556 170.757C342.043 165.819 350.273 164.173 359.601 165.27Z" fill="#AD76F3" />
            <path d="M359.601 165.27C368.929 166.368 372.221 173.501 376.062 175.147C380.321 177.276 383.967 177.671 387.001 177.168C382.5 177.221 378.061 173.243 376.062 170.5C373.246 166.636 369.001 162.5 360.501 161C352.001 159.5 341.501 161 334.001 167.5C326.501 174 290.501 211.5 285.001 217C279.501 222.5 267.501 240.534 286.001 257.767C283.866 255.457 282.133 252.634 281.138 249.221C277.297 236.052 281.686 225.627 287.173 220.14C292.66 214.653 331.069 175.695 336.556 170.757C342.043 165.819 350.273 164.173 359.601 165.27Z" fill="url(#paint25_linear_522_454)" />
            <path d="M313.794 8.67746L301.874 20.5973C305.646 19.8634 309.733 20.9031 311.305 21.5146C313.444 19.3752 317.959 14.8867 318.902 14.0484C320.081 13.0005 321.784 12.2145 323.356 12.2145C324.928 12.2145 328.464 13.2624 329.381 16.4061C330.298 19.5498 329.25 22.0386 327.94 23.3485C326.63 24.6583 317.461 33.9585 316.151 35.1373C314.842 36.3162 312.877 36.7092 310.65 36.4472C308.423 36.1852 307.637 34.4824 306.72 34.0894C304.363 32.9106 302.791 33.9585 302.005 34.7444C301.219 35.5303 300.302 38.0191 302.005 39.7219C304.887 42.7346 308.685 43.9135 311.96 43.9135C315.235 43.9135 318.902 42.7346 321.129 40.5078C323.356 38.281 329.905 32.1246 333.442 28.326C336.979 24.5274 337.241 20.5977 336.979 16.9301C336.717 13.2624 334.621 10.1187 332.001 7.89196C329.381 5.66517 326.893 5.1408 323.487 5.00981C320.081 4.87882 316.414 6.05771 313.794 8.67746Z" fill="#532A92" />
            <path d="M303.259 58.3225L315.179 46.4027C311.407 47.1366 307.32 46.0969 305.748 45.4854C303.609 47.6248 299.094 52.1133 298.151 52.9516C296.972 53.9995 295.269 54.7855 293.697 54.7855C292.126 54.7855 288.589 53.7376 287.672 50.5939C286.755 47.4502 287.803 44.9614 289.113 43.6515C290.423 42.3417 299.592 33.0415 300.902 31.8627C302.212 30.6838 304.176 30.2908 306.403 30.5528C308.63 30.8148 309.416 32.5176 310.333 32.9106C312.691 34.0894 314.262 33.0415 315.048 32.2556C315.834 31.4697 316.751 28.9809 315.048 27.2781C312.167 24.2654 308.368 23.0865 305.093 23.0865C301.819 23.0865 298.151 24.2654 295.924 26.4922C293.697 28.719 287.148 34.8754 283.611 38.674C280.075 42.4726 279.813 46.4023 280.075 50.0699C280.337 53.7376 282.432 56.8813 285.052 59.108C287.672 61.3348 290.161 61.8592 293.566 61.9902C296.972 62.1212 300.64 60.9423 303.259 58.3225Z" fill="#532A92" />
            <defs>
                <filter id="filter0_f_522_454" x="136.475" y="103.76" width="393.655" height="7.58375" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="1.03412" result="effect1_foregroundBlur_522_454" />
                </filter>
                <filter id="filter1_f_522_454" x="521.797" y="99.7971" width="15.1059" height="15.105" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="2.06824" result="effect1_foregroundBlur_522_454" />
                </filter>
                <filter id="filter2_f_522_454" x="291.718" y="323.721" width="278.283" height="8.07691" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="1.03412" result="effect1_foregroundBlur_522_454" />
                </filter>
                <filter id="filter3_f_522_454" x="561.422" y="319.959" width="15.7121" height="15.7114" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="2.06824" result="effect1_foregroundBlur_522_454" />
                </filter>
                <filter id="filter4_f_522_454" x="62.4864" y="30.4982" width="338.948" height="7.5525" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="1.03412" result="effect1_foregroundBlur_522_454" />
                </filter>
                <filter id="filter5_f_522_454" x="392.952" y="26.7248" width="15.1059" height="15.105" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="2.06824" result="effect1_foregroundBlur_522_454" />
                </filter>
                <filter id="filter6_f_522_454" x="134.876" y="350.224" width="212.684" height="7.58375" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="1.03412" result="effect1_foregroundBlur_522_454" />
                </filter>
                <filter id="filter7_f_522_454" x="338.664" y="346.436" width="15.1059" height="15.106" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="2.06824" result="effect1_foregroundBlur_522_454" />
                </filter>
                <filter id="filter8_f_522_454" x="57.3105" y="170.979" width="185.969" height="7.58375" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="1.03412" result="effect1_foregroundBlur_522_454" />
                </filter>
                <filter id="filter9_f_522_454" x="234.384" y="167.188" width="15.1059" height="15.105" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="2.06824" result="effect1_foregroundBlur_522_454" />
                </filter>
                <filter id="filter10_f_522_454" x="320.154" y="146.846" width="185.969" height="7.58375" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="1.03412" result="effect1_foregroundBlur_522_454" />
                </filter>
                <filter id="filter11_f_522_454" x="497.228" y="143.055" width="15.1059" height="15.105" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="2.06824" result="effect1_foregroundBlur_522_454" />
                </filter>
                <filter id="filter12_f_522_454" x="0.435726" y="4.65441" width="185.969" height="7.58375" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="1.03412" result="effect1_foregroundBlur_522_454" />
                </filter>
                <filter id="filter13_f_522_454" x="177.509" y="0.863517" width="15.1059" height="15.105" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="2.06824" result="effect1_foregroundBlur_522_454" />
                </filter>
                <filter id="filter14_f_522_454" x="336.586" y="388.823" width="185.969" height="7.58375" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="1.03412" result="effect1_foregroundBlur_522_454" />
                </filter>
                <filter id="filter15_f_522_454" x="513.659" y="385.031" width="15.1059" height="15.106" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="2.06824" result="effect1_foregroundBlur_522_454" />
                </filter>
                <filter id="filter16_f_522_454" x="123.674" y="256.276" width="338.948" height="7.5525" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="1.03412" result="effect1_foregroundBlur_522_454" />
                </filter>
                <filter id="filter17_f_522_454" x="454.139" y="252.502" width="15.1059" height="15.105" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="2.06824" result="effect1_foregroundBlur_522_454" />
                </filter>
                <filter id="filter18_f_522_454" x="62.5838" y="196.572" width="338.948" height="7.5525" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="1.03412" result="effect1_foregroundBlur_522_454" />
                </filter>
                <filter id="filter19_f_522_454" x="393.049" y="192.799" width="15.1059" height="15.105" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="2.06824" result="effect1_foregroundBlur_522_454" />
                </filter>
                <linearGradient id="paint0_linear_522_454" x1="680.425" y1="105.828" x2="45.8014" y2="107.356" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9B4FFD" />
                    <stop offset="0.880958" stopColor="#333647" />
                </linearGradient>
                <radialGradient id="paint1_radial_522_454" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(3.41645 3.41645) rotate(90) scale(2.27763)">
                    <stop offset="0.614184" stopColor="#333647" />
                    <stop offset="1" stopOpacity="0" />
                </radialGradient>
                <linearGradient id="paint2_linear_522_454" x1="675.167" y1="325.789" x2="228.512" y2="326.451" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9B4FFD" />
                    <stop offset="0.763891" stopColor="#333647" />
                </linearGradient>
                <radialGradient id="paint3_radial_522_454" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(3.71952 3.71952) rotate(90) scale(2.47968)">
                    <stop stopColor="#333647" />
                    <stop offset="1" stopOpacity="0" />
                </radialGradient>
                <linearGradient id="paint4_linear_522_454" x1="530.33" y1="32.566" x2="-15.1626" y2="33.7048" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9B4FFD" />
                    <stop offset="0.763891" stopColor="#333647" />
                </linearGradient>
                <radialGradient id="paint5_radial_522_454" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(3.41645 3.41645) rotate(90) scale(2.27763)">
                    <stop offset="0.614184" stopColor="#333647" />
                    <stop offset="1" stopOpacity="0" />
                </radialGradient>
                <linearGradient id="paint6_linear_522_454" x1="427.067" y1="352.292" x2="87.2891" y2="352.73" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9B4FFD" />
                    <stop offset="0.763891" stopColor="#333647" />
                </linearGradient>
                <radialGradient id="paint7_radial_522_454" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(3.41645 3.41645) rotate(90) scale(2.27763)">
                    <stop offset="0.614184" stopColor="#333647" />
                    <stop offset="1" stopOpacity="0" />
                </radialGradient>
                <linearGradient id="paint8_linear_522_454" x1="312.337" y1="173.047" x2="16.0843" y2="173.38" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9B4FFD" />
                    <stop offset="0.763891" stopColor="#333647" />
                </linearGradient>
                <radialGradient id="paint9_radial_522_454" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(3.41645 3.41645) rotate(90) scale(2.27763)">
                    <stop offset="0.614184" stopColor="#333647" />
                    <stop offset="1" stopOpacity="0" />
                </radialGradient>
                <linearGradient id="paint10_linear_522_454" x1="575.181" y1="148.914" x2="278.928" y2="149.247" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9B4FFD" />
                    <stop offset="0.763891" stopColor="#333647" />
                </linearGradient>
                <radialGradient id="paint11_radial_522_454" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(3.41645 3.41645) rotate(90) scale(2.27763)">
                    <stop offset="0.614184" stopColor="#333647" />
                    <stop offset="1" stopOpacity="0" />
                </radialGradient>
                <linearGradient id="paint12_linear_522_454" x1="255.462" y1="6.72286" x2="-40.7905" y2="7.05576" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9B4FFD" />
                    <stop offset="0.763891" stopColor="#333647" />
                </linearGradient>
                <radialGradient id="paint13_radial_522_454" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(3.41645 3.41645) rotate(90) scale(2.27763)">
                    <stop offset="0.614184" stopColor="#333647" />
                    <stop offset="1" stopOpacity="0" />
                </radialGradient>
                <linearGradient id="paint14_linear_522_454" x1="591.612" y1="390.892" x2="295.359" y2="391.225" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9B4FFD" />
                    <stop offset="0.763891" stopColor="#333647" />
                </linearGradient>
                <radialGradient id="paint15_radial_522_454" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(3.41645 3.41645) rotate(90) scale(2.27763)">
                    <stop offset="0.614184" stopColor="#333647" />
                    <stop offset="1" stopOpacity="0" />
                </radialGradient>
                <linearGradient id="paint16_linear_522_454" x1="591.518" y1="258.343" x2="46.0249" y2="259.482" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9B4FFD" />
                    <stop offset="0.763891" stopColor="#333647" />
                </linearGradient>
                <radialGradient id="paint17_radial_522_454" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(3.41645 3.41645) rotate(90) scale(2.27763)">
                    <stop offset="0.614184" stopColor="#333647" />
                    <stop offset="1" stopOpacity="0" />
                </radialGradient>
                <linearGradient id="paint18_linear_522_454" x1="530.428" y1="198.64" x2="-15.0652" y2="199.779" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9B4FFD" />
                    <stop offset="0.763891" stopColor="#333647" />
                </linearGradient>
                <radialGradient id="paint19_radial_522_454" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(3.41645 3.41645) rotate(90) scale(2.27763)">
                    <stop offset="0.614184" stopColor="#333647" />
                    <stop offset="1" stopOpacity="0" />
                </radialGradient>
                <linearGradient id="paint20_linear_522_454" x1="494" y1="97.5" x2="395.5" y2="156.5" gradientUnits="userSpaceOnUse">
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#7E2AEA" stopOpacity="0" />
                </linearGradient>
                <linearGradient id="paint21_linear_522_454" x1="454.5" y1="91" x2="397.5" y2="209.5" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#AD76F3" />
                    <stop offset="1" stopColor="#572399" />
                </linearGradient>
                <linearGradient id="paint22_linear_522_454" x1="454.5" y1="91" x2="397.5" y2="209.5" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#AD76F3" />
                    <stop offset="1" stopColor="#572399" />
                </linearGradient>
                <linearGradient id="paint23_linear_522_454" x1="410.001" y1="141.498" x2="319.501" y2="179.498" gradientUnits="userSpaceOnUse">
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#7E2AEA" stopOpacity="0" />
                </linearGradient>
                <linearGradient id="paint24_linear_522_454" x1="299.578" y1="290.953" x2="363.264" y2="181.968" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#AD76F3" />
                    <stop offset="1" stopColor="#572399" />
                </linearGradient>
                <linearGradient id="paint25_linear_522_454" x1="299.578" y1="290.953" x2="363.264" y2="181.968" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#AD76F3" />
                    <stop offset="1" stopColor="#572399" />
                </linearGradient>
            </defs>
        </svg>
    );
}
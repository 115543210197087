import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import PenaLogoLight from "@root/kitUI/img/logo_pena_links_light";
import Section from "@root/kitUI/section";

export default function AboutProject() {
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Section
      bg="#7E2AEA"
      sxsect={{
        pt: "102px",
        pb: upMd ? "94px" : "98px",
      }}
      sxcont={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Typography
          variant="h3"
          sx={{
            color: "white",
            maxWidth: "560px",
            lineHeight: "118.5%",
          }}
        >
          О нашем проекте
        </Typography>
        <Typography color="white" variant="t1" maxWidth="560px" mt="23px">
          Текст-заполнитель — это текст, который имеет некоторые,т, который имеет некоторые характеристики реального
          письменного текста, но является. Текст-заполнитель — это текст, который имеет некоторые характеристики
          реального письменного текста, но является
        </Typography>
      </Box>
      {upMd && (
        <PenaLogoLight
          blobColor="#5A1EA8"
          style={{
            alignSelf: "end",
            opacity: 0.5,
            height: "62px",
            minWidth: "160px",
            width: "160px",
            marginBottom: "3px",
          }}
          textColor="white"
        />
      )}
    </Section>
  );
}

import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";

import NumberIcon from "@root/kitUI/Icons/NumberIcon";
import Section from "@root/kitUI/section";

import LinkGenerationCard from "./LinkGenerationCard";

export default function Buying() {
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <>
      <Section
        sxsect={{
          py: upMd ? "130px" : "40px",
        }}
      >
        <Typography variant="h3" maxWidth={upMd ? undefined : "273px"}>
          Покупка коротких ссылок
        </Typography>
        <Box
          sx={{
            mt: upMd ? "35px" : "30px",
            display: "flex",
            flexDirection: "column",
            gap: upMd ? "30px" : "26px",
          }}
        >
          <LinkGenerationCard
            id="three-symbols-link"
            headerText="Сокращенная трехсимвольная ссылка"
            icon={<NumberIcon number={3} color="#FB5607" backgroundColor="#FEDFD0" sx={{ alignSelf: "start" }} />}
          />
          <LinkGenerationCard
            id="four-symbols-link"
            headerText="Сокращенная четырехсимвольная ссылка"
            icon={<NumberIcon number={4} color="#FB5607" backgroundColor="#FEDFD0" sx={{ alignSelf: "start" }} />}
          />
        </Box>
      </Section>
    </>
  );
}

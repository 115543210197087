import { ReactNode, useMemo } from "react";
import { Box, SxProps, Theme, useMediaQuery, useTheme } from "@mui/material";


interface Props {
    children: ReactNode[];
    sx?: SxProps<Theme>;
}

export default function ColumnLayout({ children, sx }: Props) {
    const theme = useTheme();
    const upLg = useMediaQuery(theme.breakpoints.up("lg"));
    const upMd = useMediaQuery(theme.breakpoints.up("md"));

    const numberOfColumns = upLg ? 3 : upMd ? 2 : 1;

    const columns = useMemo(() => {
        const columns: ReactNode[][] = [...Array(numberOfColumns)].map(() => []);
        children.forEach((element, index) => {
            columns[index % numberOfColumns].push(element);
        });
        return columns;
    }, [children, numberOfColumns]);

    return (
        <Box sx={{
            display: "grid",
            gap: "40px",
            gridTemplateColumns: `repeat(${numberOfColumns}, 1fr)`,
            ...sx,
        }}>
            {columns.map((column, index) =>
                <Box
                    key={index}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "30px",
                    }}
                >
                    {column}
                </Box>
            )}
        </Box>
    );
}
import { Box, SxProps, Theme, useMediaQuery, useTheme } from "@mui/material";

import macbookImage2 from "@kitUI/img/landing/macbook2.png";

interface Props {
  sx?: SxProps<Theme>;
}

export default function LaptopWithTrails2({ sx }: Props) {
  const theme = useTheme();
  const upLg = useMediaQuery(theme.breakpoints.up("lg"));
  const isTablet = useMediaQuery(theme.breakpoints.up(1200));
  const isMobile = useMediaQuery(theme.breakpoints.up(600));

  return (
    <Box
      sx={{
        position: "relative",
        transform: upLg ? "scale(1.132) translate(10.85%, -3.3%)" : "scale(1.16)",
        maxHeight: upLg ? "350px" : undefined,
        display: "flex",
        alignItems: "center",
        mb: upLg ? undefined : "47px",
        flex: "1.52 1.52 0",
        ...sx,
      }}
    >
      <img
        alt="macbookImage"
        src={macbookImage2}
        style={{
          marginLeft: isMobile ? "-53px" : "0" || isTablet ? "0" : "44px",
          position: "relative",
          zIndex: 1,
          width: "100%",
        }}
      />
    </Box>
  );
}

import { Box } from "@mui/material";


export default function FilterIcon() {

    return (
        <Box sx={{
            width: "44px",
            height: "44px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexShrink: 0,
        }}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.5 3.25C1.5 2.2835 2.2835 1.5 3.25 1.5H17.25C18.2165 1.5 19 2.2835 19 3.25V3.96532C19 4.63021 18.7477 5.27033 18.294 5.75641L13.3457 11.0582C13.0432 11.3823 12.875 11.809 12.875 12.2523V15.8342C12.875 16.1656 12.6877 16.4686 12.3913 16.6168L8.89131 18.3668C8.30952 18.6577 7.625 18.2347 7.625 17.5842V12.2523C7.625 11.809 7.45679 11.3823 7.15435 11.0582L2.20598 5.75641C1.75231 5.27033 1.5 4.63021 1.5 3.96532V3.25Z" stroke="#7E2AEA" strokeWidth="1.5" />
            </svg>
        </Box>
    );
}
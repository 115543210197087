import { useMediaQuery, Typography, useTheme, Box } from "@mui/material";
import Chart from "@root/kitUI/Chart/Chart";
import createChartMockData from "@root/kitUI/Chart/mockData";
import Section from "@root/kitUI/section";
import { useMemo } from "react";

export default function LinkTransitionsChart() {
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up("md"));

  const transitionsPointData = useMemo(() => createChartMockData(200), []);
  const uniqueTransitionsPointData = useMemo(() => createChartMockData(200), []);

  return (
    <>
      <Section
        sxsect={{
          py: upMd ? "130px" : "40px",
        }}
      >
        <Typography variant="h3">График переходов</Typography>
        <Box
          sx={{
            mt: upMd ? "40px" : "30px",
          }}
        >
          <Chart transitionsPointData={transitionsPointData} uniqueTransitionsPointData={uniqueTransitionsPointData} />
        </Box>
      </Section>
    </>
  );
}

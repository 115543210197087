import { forwardRef, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";

import NavMenuItem from "../NavMenuItem";

import LogoLight from "@kitUI/img/logo_pena_links_light";
import CloseIcon from "@root/kitUI/Icons/CloseIcon";

import BurgerIcon from "../../Icons/BurgerIcon";

const logoStyle = { minWidth: "101px", width: "101px", height: "39px" };

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  menuItems: { path: string; title: string }[];
}

export default function MobileLandingNavbar({ menuItems }: Props) {
  const theme = useTheme();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <Box
      component="nav"
      sx={{
        width: "100%",
          position: "fixed",
        height: "51px",
        backgroundColor: "#2A2C3A",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        zIndex: theme.zIndex.header,
        px: "16px",
        gap: "20px",
      }}
    >
      <LogoLight style={logoStyle} textColor="white" />
      <IconButton onClick={() => setIsDialogOpen(true)} sx={{ p: 0 }}>
        <BurgerIcon sx={{ color: "white" }} />
      </IconButton>
      <Dialog
        fullScreen
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        TransitionComponent={Transition}
        PaperProps={{
          sx: {
            height: "100%",
            display: "flex",
            flexDirection: "column",
            background: "#252734",
            pb: "20px",
            gap: "18px",
          },
        }}
      >
        <AppBar
          sx={{
            position: "relative",
            boxShadow: "none",
            borderRadius: 0,
            background: "#252734",
          }}
        >
          <Toolbar
            disableGutters
            sx={{
              display: "flex",
              justifyContent: "space-between",
              px: "16px",
              minHeight: "51px",
              [theme.breakpoints.up("sm")]: {
                minHeight: "51px",
              },
            }}
          >
            <LogoLight style={logoStyle} textColor="white" />
            <IconButton onClick={() => setIsDialogOpen(false)} aria-label="close" sx={{ p: 0 }}>
              <CloseIcon sx={{ color: "white" }} />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
          }}
        >
          {menuItems.map((element, index) => (
            <NavMenuItem key={index} mode="dark" fullWidth to={element.path}>
              {element.title}
            </NavMenuItem>
          ))}
        </Box>
        <Box
          sx={{
            mt: "auto",
            display: "flex",
            justifyContent: "center",
            gap: "20px",
            px: "16px",
          }}
        >
          <Button
            variant="template"
            sx={{
              p: "9px 31px",
            }}
          >
            Войти
          </Button>
          <Button
            variant="outlined"
            sx={{
              color: "white",
              border: "1px solid white",
              textTransform: "none",
              fontWeight: "400",
              fontSize: "18px",
              lineHeight: "24px",
              borderRadius: "8px",
              p: "9px 31px",
            }}
          >
            Регистрация
          </Button>
        </Box>
      </Dialog>
    </Box>
  );
}

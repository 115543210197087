import { useLocation } from "react-router-dom";

import LandingNavbar from "./LandingNavbar/LandingNavbar";
import NavbarLoggedIn from "./NavbarLoggedIn/NavbarLoggedIn";

export default function Navbar() {
  const location = useLocation();

  if (location.pathname === "/") return <LandingNavbar />;

  return <NavbarLoggedIn />;
}

import { Box, FormControl, SxProps, TextField, Theme, Typography, useMediaQuery, useTheme } from "@mui/material";
import { ChangeEventHandler } from "react";

interface Props {
  id: string;
  label?: string;
  placeholder?: string;
  backgroundColor?: string;
  disabled?: boolean;
  inputColor?: string;
  sx?: SxProps<Theme>;
  textFieldSx?: SxProps<Theme>;
  multiline?: boolean;
  maxRows?: number;
  value?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

export default function CustomTextField({
  id,
  label,
  placeholder,
  backgroundColor,
  disabled,
  inputColor,
  sx,
  textFieldSx,
  multiline,
  maxRows,
  value,
  onChange,
}: Props) {
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box sx={sx}>
      {label && (
        <Typography
          variant="t1"
          sx={{
            fontWeight: 500,
            color: "#4D4D4D",
            mb: upMd ? "10px" : "11.5px",
            lineHeight: "100%",
          }}
        >
          {label}
        </Typography>
      )}
      <FormControl
        fullWidth
        variant="standard"
        sx={{
          p: 0,
        }}
      >
        <TextField
          multiline={multiline}
          maxRows={maxRows}
          id={id}
          value={value}
          onChange={onChange}
          fullWidth
          placeholder={placeholder}
          disabled={disabled}
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "10px",
              padding: 0,
              overflow: "hidden",
              backgroundColor,
              color: "black",
              "& fieldset": {
                border: "1px solid #9A9AAF",
              },
              "&:hover fieldset": {
                borderColor: "#5b5d6e",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#995BEE",
              },
              "& ::placeholder": {
                color: "#9A9AAF",
                opacity: 1,
              },
              "& input": {
                p: "11px 20px",
                WebkitTextFillColor: inputColor,
              },
              "& .MuiInputBase-inputMultiline": {
                overflow: "hidden",
                padding: "10px 20px",
                paddingBottom: "8px",
                WebkitTextFillColor: inputColor,
                lineHeight: "21.33px",
              },
            },
            ...textFieldSx,
          }}
        />
      </FormControl>
    </Box>
  );
}

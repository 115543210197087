import * as React from "react";
import Box from "@mui/material/Box";
import { SxProps, Theme } from "@mui/material";
import { useTheme } from "@mui/material/styles";

interface Props {
  sxsect?: SxProps<Theme>;
  mwidth?: string;
  tag?: React.ElementType;
  bg?: string;
  children: React.ReactNode;
  sxcont?: SxProps<Theme>;
}

export default function Section(props: Props) {
  const theme = useTheme();
  return (
    <Box
      component={props.tag ?? "section"}
      backgroundColor={props.bg}
      sx={{
        width: "100%",
        fontFamily: "Rubik",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...props.sxsect,
      }}
    >
      <Box
        maxWidth={props.mwidth ?? "1200px"}
        sx={{
          width: "100%",
          padding: "0 20px",
          [theme.breakpoints.down("md")]: {
            padding: "0 20px",
          },
          ...props.sxcont,
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
}

import Hero from "./Hero/Hero";
import AboutProject from "./AboutProject";
import HowItWorks from "./HowItWorks/HowItWorks";
import Advantages from "./Advantages/Advantages";
import Profits from "./Profits/Profits";
import Tariffs from "./Tariffs/Tariffs";
import RegisterNow from "./RegisterNow";

import Footer from "@root/kitUI/footer";

export default function Landing() {
  return (
    <>
      <Hero />
      <AboutProject />
      <HowItWorks />
      <Advantages />
      <Profits />
      <Tariffs />
      <RegisterNow />
      <Footer />
    </>
  );
}

import { MouseEventHandler, ReactNode } from "react";
import { Link, useLocation } from "react-router-dom";
import { Button, SxProps, Theme } from "@mui/material";

interface Props {
  to: string;
  children: ReactNode;
  mode?: "light" | "dark";
  defaultColor?: string;
  sx?: SxProps<Theme>;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  fullWidth?: boolean;
}

export default function NavMenuItem({
  children,
  mode = "light",
  to,
  defaultColor,
  sx,
  onClick,
  fullWidth = false,
}: Props) {
  const location = useLocation();

  const mainColor = defaultColor ?? (mode === "light" ? "black" : "white");
  const color = location.pathname === to ? "#7E2AEA" : mainColor;

  const fullWidthSx = {
    width: "100%",
    py: "12px",
    pl: "40px",
    borderRadius: 0,
    justifyContent: "start",
    "&:hover": {
      backgroundColor: mode === "light" ? "#F2F3F7" : "#333647",
    },
  };

  return (
    <Button
      disableRipple
      {...{
        to: !onClick ? to : undefined,
        component: !onClick ? Link : undefined,
        onClick,
      }}
      variant="text"
      sx={{
        color,
        textTransform: "none",
        fontSize: "16px",
        lineHeight: "20px",
        py: "9px",
        ...(fullWidth && fullWidthSx),
        ...sx,
        "&:hover": {
          color: "#7E2AEA",
          background: "none",
        },
      }}
    >
      {children}
    </Button>
  );
}

import { Box, SxProps, Theme, useMediaQuery, useTheme } from "@mui/material";

import macbookImage1 from "@kitUI/img/landing/macbook1.png";

interface Props {
  sx?: SxProps<Theme>;
}

export default function LaptopWithTrails1({ sx }: Props) {
  const theme = useTheme();
  const upLg = useMediaQuery(theme.breakpoints.up("lg"));
  const isTablet = useMediaQuery(theme.breakpoints.up(1200));
  const isMobile = useMediaQuery(theme.breakpoints.up(600));

  return (
    <Box
      sx={{
        position: "relative",
        transform: upLg ? "scale(1.149) translate(-10.95%, 13.5%)" : "scale(1.2)",
        transformOrigin: upLg ? undefined : "50% 0",
        maxHeight: upLg ? "350px" : undefined,
        display: "flex",
        alignItems: "center",
        flex: "1.52 1.52 0",
        mb: upLg ? undefined : "33px",

        ...sx,
      }}
    >
      <img
        alt="macbookImage"
        src={macbookImage1}
        style={{
          marginLeft: isMobile ? "44px" : "0" || isTablet ? "0" : "57px",
          position: "relative",
          zIndex: 1,
          width: "100%",
        }}
      />
    </Box>
  );
}

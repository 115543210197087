import { CSSProperties } from "react";


interface Props {
    style: CSSProperties;
}

export default function Trails({ style }: Props) {

    return (
        <svg width="290" height="117" viewBox="0 0 290 117" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
            <g filter="url(#filter0_f_522_588)">
                <path fillRule="evenodd" clipRule="evenodd" d="M85.1687 5.84202L86.0823 85.8604L86.9959 85.8604L87.9095 5.84202C87.9095 5.84202 87.9095 5.29395 86.5391 5.29395C85.1687 5.29395 85.1687 5.84202 85.1687 5.84202Z" fill="url(#paint0_linear_522_588)" />
            </g>
            <g filter="url(#filter1_f_522_588)">
                <ellipse cx="2.71648" cy="0.706644" rx="2.71648" ry="0.706644" transform="matrix(-1 -5.50821e-08 -2.11747e-07 1 89.097 4.32031)" fill="#9B4FFD" />
                <ellipse cx="2.71648" cy="0.706644" rx="2.71648" ry="0.706644" transform="matrix(-1 -5.50821e-08 -2.11747e-07 1 89.097 4.32031)" fill="url(#paint1_radial_522_588)" />
            </g>
            <g filter="url(#filter2_f_522_588)">
                <path fillRule="evenodd" clipRule="evenodd" d="M260.054 17.4336L261.099 73.7511L262.143 73.7511L263.187 17.4336C263.187 17.4336 263.188 17.0479 261.621 17.0479C260.054 17.0479 260.054 17.4336 260.054 17.4336Z" fill="url(#paint2_linear_522_588)" />
            </g>
            <g filter="url(#filter3_f_522_588)">
                <ellipse cx="2.95745" cy="0.76933" rx="2.95745" ry="0.76933" transform="matrix(-1 -5.50821e-08 -2.11747e-07 1 264.625 16)" fill="#9B4FFD" />
                <ellipse cx="2.95745" cy="0.76933" rx="2.95745" ry="0.76933" transform="matrix(-1 -5.50821e-08 -2.11747e-07 1 264.625 16)" fill="url(#paint3_radial_522_588)" />
            </g>
            <g opacity="0.4">
                <g filter="url(#filter4_f_522_588)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M26.9196 32.3861L27.8251 101.166L28.7306 101.166L29.6361 32.3861C29.6361 32.3861 29.6361 31.915 28.2779 31.915C26.9196 31.915 26.9196 32.3861 26.9196 32.3861Z" fill="url(#paint4_linear_522_588)" />
                </g>
                <g filter="url(#filter5_f_522_588)">
                    <ellipse cx="2.71648" cy="0.706644" rx="2.71648" ry="0.706644" transform="matrix(-1 -5.50821e-08 -2.11747e-07 1 30.9955 30.9727)" fill="#9B4FFD" />
                    <ellipse cx="2.71648" cy="0.706644" rx="2.71648" ry="0.706644" transform="matrix(-1 -5.50821e-08 -2.11747e-07 1 30.9955 30.9727)" fill="url(#paint5_radial_522_588)" />
                </g>
            </g>
            <g opacity="0.4">
                <g filter="url(#filter6_f_522_588)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M281.145 43.3501L282.059 86.1918L282.972 86.1918L283.886 43.3501C283.886 43.3501 283.886 43.0566 282.516 43.0566C281.145 43.0566 281.145 43.3501 281.145 43.3501Z" fill="url(#paint6_linear_522_588)" />
                </g>
                <g filter="url(#filter7_f_522_588)">
                    <ellipse cx="2.71648" cy="0.706644" rx="2.71648" ry="0.706644" transform="matrix(-1 -5.50821e-08 -2.11747e-07 1 285.206 42.1992)" fill="#9B4FFD" />
                    <ellipse cx="2.71648" cy="0.706644" rx="2.71648" ry="0.706644" transform="matrix(-1 -5.50821e-08 -2.11747e-07 1 285.206 42.1992)" fill="url(#paint7_radial_522_588)" />
                </g>
            </g>
            <g opacity="0.15">
                <g filter="url(#filter8_f_522_588)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M138.622 64.8828L139.535 102.236L140.449 102.236L141.363 64.8828C141.363 64.8828 141.363 64.627 139.992 64.627C138.622 64.627 138.622 64.8828 138.622 64.8828Z" fill="url(#paint8_linear_522_588)" />
                </g>
                <g filter="url(#filter9_f_522_588)">
                    <ellipse cx="2.71648" cy="0.706644" rx="2.71648" ry="0.706644" transform="matrix(-1 -5.50821e-08 -2.11747e-07 1 142.683 63.7695)" fill="#9B4FFD" />
                    <ellipse cx="2.71648" cy="0.706644" rx="2.71648" ry="0.706644" transform="matrix(-1 -5.50821e-08 -2.11747e-07 1 142.683 63.7695)" fill="url(#paint9_radial_522_588)" />
                </g>
            </g>
            <g opacity="0.15">
                <g filter="url(#filter10_f_522_588)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M119.426 10.5156L120.34 47.8693L121.254 47.8693L122.167 10.5156C122.167 10.5156 122.167 10.2598 120.797 10.2598C119.426 10.2598 119.426 10.5156 119.426 10.5156Z" fill="url(#paint10_linear_522_588)" />
                </g>
                <g filter="url(#filter11_f_522_588)">
                    <ellipse cx="2.71648" cy="0.706644" rx="2.71648" ry="0.706644" transform="matrix(-1 -5.50821e-08 -2.11747e-07 1 123.488 9.40234)" fill="#9B4FFD" />
                    <ellipse cx="2.71648" cy="0.706644" rx="2.71648" ry="0.706644" transform="matrix(-1 -5.50821e-08 -2.11747e-07 1 123.488 9.40234)" fill="url(#paint11_radial_522_588)" />
                </g>
            </g>
            <g opacity="0.15">
                <g filter="url(#filter12_f_522_588)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M6.37185 76.6465L7.28545 114L8.19906 114L9.11267 76.6465C9.11267 76.6465 9.11267 76.3906 7.74226 76.3906C6.37185 76.3906 6.37185 76.6465 6.37185 76.6465Z" fill="url(#paint12_linear_522_588)" />
                </g>
                <g filter="url(#filter13_f_522_588)">
                    <ellipse cx="2.71648" cy="0.706644" rx="2.71648" ry="0.706644" transform="matrix(-1 -5.50821e-08 -2.11747e-07 1 10.433 75.5332)" fill="#9B4FFD" />
                    <ellipse cx="2.71648" cy="0.706644" rx="2.71648" ry="0.706644" transform="matrix(-1 -5.50821e-08 -2.11747e-07 1 10.433 75.5332)" fill="url(#paint13_radial_522_588)" />
                </g>
            </g>
            <g opacity="0.4">
                <g filter="url(#filter14_f_522_588)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M206.435 19.7279L207.341 88.5079L208.246 88.5079L209.152 19.7279C209.152 19.7279 209.152 19.2568 207.793 19.2568C206.435 19.2568 206.435 19.7279 206.435 19.7279Z" fill="url(#paint14_linear_522_588)" />
                </g>
                <g filter="url(#filter15_f_522_588)">
                    <ellipse cx="2.71648" cy="0.706644" rx="2.71648" ry="0.706644" transform="matrix(-1 -5.50821e-08 -2.11747e-07 1 210.511 18.3154)" fill="#9B4FFD" />
                    <ellipse cx="2.71648" cy="0.706644" rx="2.71648" ry="0.706644" transform="matrix(-1 -5.50821e-08 -2.11747e-07 1 210.511 18.3154)" fill="url(#paint15_radial_522_588)" />
                </g>
            </g>
            <g opacity="0.4">
                <g filter="url(#filter16_f_522_588)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M158.966 32.3637L159.872 101.144L160.777 101.144L161.683 32.3637C161.683 32.3637 161.683 31.8926 160.325 31.8926C158.966 31.8926 158.966 32.3637 158.966 32.3637Z" fill="url(#paint16_linear_522_588)" />
                </g>
                <g filter="url(#filter17_f_522_588)">
                    <ellipse cx="2.71648" cy="0.706644" rx="2.71648" ry="0.706644" transform="matrix(-1 -5.50821e-08 -2.11747e-07 1 163.042 30.9502)" fill="#9B4FFD" />
                    <ellipse cx="2.71648" cy="0.706644" rx="2.71648" ry="0.706644" transform="matrix(-1 -5.50821e-08 -2.11747e-07 1 163.042 30.9502)" fill="url(#paint17_radial_522_588)" />
                </g>
            </g>
            <g filter="url(#filter18_f_522_588)">
                <path fillRule="evenodd" clipRule="evenodd" d="M85.1687 5.84202L86.0823 85.8604L86.9959 85.8604L87.9095 5.84202C87.9095 5.84202 87.9095 5.29395 86.5391 5.29395C85.1687 5.29395 85.1687 5.84202 85.1687 5.84202Z" fill="url(#paint18_linear_522_588)" />
            </g>
            <g filter="url(#filter19_f_522_588)">
                <ellipse cx="2.71648" cy="0.706644" rx="2.71648" ry="0.706644" transform="matrix(-1 -5.50821e-08 -2.11747e-07 1 89.097 4.32031)" fill="#9B4FFD" />
                <ellipse cx="2.71648" cy="0.706644" rx="2.71648" ry="0.706644" transform="matrix(-1 -5.50821e-08 -2.11747e-07 1 89.097 4.32031)" fill="url(#paint19_radial_522_588)" />
            </g>
            <g filter="url(#filter20_f_522_588)">
                <path fillRule="evenodd" clipRule="evenodd" d="M260.054 17.4336L261.099 73.7511L262.143 73.7511L263.187 17.4336C263.187 17.4336 263.188 17.0479 261.621 17.0479C260.054 17.0479 260.054 17.4336 260.054 17.4336Z" fill="url(#paint20_linear_522_588)" />
            </g>
            <g filter="url(#filter21_f_522_588)">
                <ellipse cx="2.95745" cy="0.76933" rx="2.95745" ry="0.76933" transform="matrix(-1 -5.50821e-08 -2.11747e-07 1 264.625 16)" fill="#9B4FFD" />
                <ellipse cx="2.95745" cy="0.76933" rx="2.95745" ry="0.76933" transform="matrix(-1 -5.50821e-08 -2.11747e-07 1 264.625 16)" fill="url(#paint21_radial_522_588)" />
            </g>
            <g opacity="0.4">
                <g filter="url(#filter22_f_522_588)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M26.9196 32.3861L27.8251 101.166L28.7306 101.166L29.6361 32.3861C29.6361 32.3861 29.6361 31.915 28.2779 31.915C26.9196 31.915 26.9196 32.3861 26.9196 32.3861Z" fill="url(#paint22_linear_522_588)" />
                </g>
                <g filter="url(#filter23_f_522_588)">
                    <ellipse cx="2.71648" cy="0.706644" rx="2.71648" ry="0.706644" transform="matrix(-1 -5.50821e-08 -2.11747e-07 1 30.9955 30.9727)" fill="#9B4FFD" />
                    <ellipse cx="2.71648" cy="0.706644" rx="2.71648" ry="0.706644" transform="matrix(-1 -5.50821e-08 -2.11747e-07 1 30.9955 30.9727)" fill="url(#paint23_radial_522_588)" />
                </g>
            </g>
            <g opacity="0.4">
                <g filter="url(#filter24_f_522_588)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M281.145 43.3501L282.059 86.1918L282.972 86.1918L283.886 43.3501C283.886 43.3501 283.886 43.0566 282.516 43.0566C281.145 43.0566 281.145 43.3501 281.145 43.3501Z" fill="url(#paint24_linear_522_588)" />
                </g>
                <g filter="url(#filter25_f_522_588)">
                    <ellipse cx="2.71648" cy="0.706644" rx="2.71648" ry="0.706644" transform="matrix(-1 -5.50821e-08 -2.11747e-07 1 285.206 42.1992)" fill="#9B4FFD" />
                    <ellipse cx="2.71648" cy="0.706644" rx="2.71648" ry="0.706644" transform="matrix(-1 -5.50821e-08 -2.11747e-07 1 285.206 42.1992)" fill="url(#paint25_radial_522_588)" />
                </g>
            </g>
            <g opacity="0.15">
                <g filter="url(#filter26_f_522_588)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M138.622 64.8828L139.535 102.236L140.449 102.236L141.363 64.8828C141.363 64.8828 141.363 64.627 139.992 64.627C138.622 64.627 138.622 64.8828 138.622 64.8828Z" fill="url(#paint26_linear_522_588)" />
                </g>
                <g filter="url(#filter27_f_522_588)">
                    <ellipse cx="2.71648" cy="0.706644" rx="2.71648" ry="0.706644" transform="matrix(-1 -5.50821e-08 -2.11747e-07 1 142.683 63.7695)" fill="#9B4FFD" />
                    <ellipse cx="2.71648" cy="0.706644" rx="2.71648" ry="0.706644" transform="matrix(-1 -5.50821e-08 -2.11747e-07 1 142.683 63.7695)" fill="url(#paint27_radial_522_588)" />
                </g>
            </g>
            <g opacity="0.15">
                <g filter="url(#filter28_f_522_588)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M119.426 10.5156L120.34 47.8693L121.254 47.8693L122.167 10.5156C122.167 10.5156 122.167 10.2598 120.797 10.2598C119.426 10.2598 119.426 10.5156 119.426 10.5156Z" fill="url(#paint28_linear_522_588)" />
                </g>
                <g filter="url(#filter29_f_522_588)">
                    <ellipse cx="2.71648" cy="0.706644" rx="2.71648" ry="0.706644" transform="matrix(-1 -5.50821e-08 -2.11747e-07 1 123.488 9.40234)" fill="#9B4FFD" />
                    <ellipse cx="2.71648" cy="0.706644" rx="2.71648" ry="0.706644" transform="matrix(-1 -5.50821e-08 -2.11747e-07 1 123.488 9.40234)" fill="url(#paint29_radial_522_588)" />
                </g>
            </g>
            <g opacity="0.15">
                <g filter="url(#filter30_f_522_588)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M6.37185 76.6465L7.28545 114L8.19906 114L9.11267 76.6465C9.11267 76.6465 9.11267 76.3906 7.74226 76.3906C6.37185 76.3906 6.37185 76.6465 6.37185 76.6465Z" fill="url(#paint30_linear_522_588)" />
                </g>
                <g filter="url(#filter31_f_522_588)">
                    <ellipse cx="2.71648" cy="0.706644" rx="2.71648" ry="0.706644" transform="matrix(-1 -5.50821e-08 -2.11747e-07 1 10.433 75.5332)" fill="#9B4FFD" />
                    <ellipse cx="2.71648" cy="0.706644" rx="2.71648" ry="0.706644" transform="matrix(-1 -5.50821e-08 -2.11747e-07 1 10.433 75.5332)" fill="url(#paint31_radial_522_588)" />
                </g>
            </g>
            <g opacity="0.4">
                <g filter="url(#filter32_f_522_588)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M206.435 19.7279L207.341 88.5079L208.246 88.5079L209.152 19.7279C209.152 19.7279 209.152 19.2568 207.793 19.2568C206.435 19.2568 206.435 19.7279 206.435 19.7279Z" fill="url(#paint32_linear_522_588)" />
                </g>
                <g filter="url(#filter33_f_522_588)">
                    <ellipse cx="2.71648" cy="0.706644" rx="2.71648" ry="0.706644" transform="matrix(-1 -5.50821e-08 -2.11747e-07 1 210.511 18.3154)" fill="#9B4FFD" />
                    <ellipse cx="2.71648" cy="0.706644" rx="2.71648" ry="0.706644" transform="matrix(-1 -5.50821e-08 -2.11747e-07 1 210.511 18.3154)" fill="url(#paint33_radial_522_588)" />
                </g>
            </g>
            <g opacity="0.4">
                <g filter="url(#filter34_f_522_588)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M158.966 32.3637L159.872 101.144L160.777 101.144L161.683 32.3637C161.683 32.3637 161.683 31.8926 160.325 31.8926C158.966 31.8926 158.966 32.3637 158.966 32.3637Z" fill="url(#paint34_linear_522_588)" />
                </g>
                <g filter="url(#filter35_f_522_588)">
                    <ellipse cx="2.71648" cy="0.706644" rx="2.71648" ry="0.706644" transform="matrix(-1 -5.50821e-08 -2.11747e-07 1 163.042 30.9502)" fill="#9B4FFD" />
                    <ellipse cx="2.71648" cy="0.706644" rx="2.71648" ry="0.706644" transform="matrix(-1 -5.50821e-08 -2.11747e-07 1 163.042 30.9502)" fill="url(#paint35_radial_522_588)" />
                </g>
            </g>
            <defs>
                <filter id="filter0_f_522_588" x="83.1005" y="3.2257" width="6.87727" height="84.7029" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="1.03412" result="effect1_foregroundBlur_522_588" />
                </filter>
                <filter id="filter1_f_522_588" x="79.5276" y="0.183829" width="13.7059" height="9.68605" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="2.06824" result="effect1_foregroundBlur_522_588" />
                </filter>
                <filter id="filter2_f_522_588" x="257.986" y="14.9796" width="7.26966" height="60.8396" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="1.03412" result="effect1_foregroundBlur_522_588" />
                </filter>
                <filter id="filter3_f_522_588" x="254.574" y="11.8635" width="14.1879" height="9.81203" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="2.06824" result="effect1_foregroundBlur_522_588" />
                </filter>
                <filter id="filter4_f_522_588" x="24.8514" y="29.8468" width="6.85297" height="73.3875" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="1.03412" result="effect1_foregroundBlur_522_588" />
                </filter>
                <filter id="filter5_f_522_588" x="21.426" y="26.8362" width="13.7059" height="9.68605" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="2.06824" result="effect1_foregroundBlur_522_588" />
                </filter>
                <filter id="filter6_f_522_588" x="279.077" y="40.9884" width="6.87733" height="47.2712" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="1.03412" result="effect1_foregroundBlur_522_588" />
                </filter>
                <filter id="filter7_f_522_588" x="275.637" y="38.0627" width="13.7059" height="9.68605" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="2.06824" result="effect1_foregroundBlur_522_588" />
                </filter>
                <filter id="filter8_f_522_588" x="136.554" y="62.5587" width="6.87733" height="41.7459" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="1.03412" result="effect1_foregroundBlur_522_588" />
                </filter>
                <filter id="filter9_f_522_588" x="133.113" y="59.633" width="13.7059" height="9.68605" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="2.06824" result="effect1_foregroundBlur_522_588" />
                </filter>
                <filter id="filter10_f_522_588" x="117.358" y="8.19152" width="6.87733" height="41.7459" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="1.03412" result="effect1_foregroundBlur_522_588" />
                </filter>
                <filter id="filter11_f_522_588" x="113.918" y="5.26586" width="13.7059" height="9.68605" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="2.06824" result="effect1_foregroundBlur_522_588" />
                </filter>
                <filter id="filter12_f_522_588" x="4.30358" y="74.3224" width="6.87733" height="41.7459" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="1.03412" result="effect1_foregroundBlur_522_588" />
                </filter>
                <filter id="filter13_f_522_588" x="0.863517" y="71.3967" width="13.7059" height="9.68605" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="2.06824" result="effect1_foregroundBlur_522_588" />
                </filter>
                <filter id="filter14_f_522_588" x="204.367" y="17.1886" width="6.85297" height="73.3875" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="1.03412" result="effect1_foregroundBlur_522_588" />
                </filter>
                <filter id="filter15_f_522_588" x="200.942" y="14.1789" width="13.7059" height="9.68605" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="2.06824" result="effect1_foregroundBlur_522_588" />
                </filter>
                <filter id="filter16_f_522_588" x="156.898" y="29.8243" width="6.85297" height="73.3875" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="1.03412" result="effect1_foregroundBlur_522_588" />
                </filter>
                <filter id="filter17_f_522_588" x="153.473" y="26.8137" width="13.7059" height="9.68605" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="2.06824" result="effect1_foregroundBlur_522_588" />
                </filter>
                <filter id="filter18_f_522_588" x="83.1005" y="3.2257" width="6.87727" height="84.7029" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="1.03412" result="effect1_foregroundBlur_522_588" />
                </filter>
                <filter id="filter19_f_522_588" x="79.5276" y="0.183829" width="13.7059" height="9.68605" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="2.06824" result="effect1_foregroundBlur_522_588" />
                </filter>
                <filter id="filter20_f_522_588" x="257.986" y="14.9796" width="7.26966" height="60.8396" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="1.03412" result="effect1_foregroundBlur_522_588" />
                </filter>
                <filter id="filter21_f_522_588" x="254.574" y="11.8635" width="14.1879" height="9.81203" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="2.06824" result="effect1_foregroundBlur_522_588" />
                </filter>
                <filter id="filter22_f_522_588" x="24.8514" y="29.8468" width="6.85297" height="73.3875" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="1.03412" result="effect1_foregroundBlur_522_588" />
                </filter>
                <filter id="filter23_f_522_588" x="21.426" y="26.8362" width="13.7059" height="9.68605" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="2.06824" result="effect1_foregroundBlur_522_588" />
                </filter>
                <filter id="filter24_f_522_588" x="279.077" y="40.9884" width="6.87733" height="47.2712" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="1.03412" result="effect1_foregroundBlur_522_588" />
                </filter>
                <filter id="filter25_f_522_588" x="275.637" y="38.0627" width="13.7059" height="9.68605" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="2.06824" result="effect1_foregroundBlur_522_588" />
                </filter>
                <filter id="filter26_f_522_588" x="136.554" y="62.5587" width="6.87733" height="41.7459" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="1.03412" result="effect1_foregroundBlur_522_588" />
                </filter>
                <filter id="filter27_f_522_588" x="133.113" y="59.633" width="13.7059" height="9.68605" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="2.06824" result="effect1_foregroundBlur_522_588" />
                </filter>
                <filter id="filter28_f_522_588" x="117.358" y="8.19152" width="6.87733" height="41.7459" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="1.03412" result="effect1_foregroundBlur_522_588" />
                </filter>
                <filter id="filter29_f_522_588" x="113.918" y="5.26586" width="13.7059" height="9.68605" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="2.06824" result="effect1_foregroundBlur_522_588" />
                </filter>
                <filter id="filter30_f_522_588" x="4.30358" y="74.3224" width="6.87733" height="41.7459" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="1.03412" result="effect1_foregroundBlur_522_588" />
                </filter>
                <filter id="filter31_f_522_588" x="0.863517" y="71.3967" width="13.7059" height="9.68605" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="2.06824" result="effect1_foregroundBlur_522_588" />
                </filter>
                <filter id="filter32_f_522_588" x="204.367" y="17.1886" width="6.85297" height="73.3875" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="1.03412" result="effect1_foregroundBlur_522_588" />
                </filter>
                <filter id="filter33_f_522_588" x="200.942" y="14.1789" width="13.7059" height="9.68605" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="2.06824" result="effect1_foregroundBlur_522_588" />
                </filter>
                <filter id="filter34_f_522_588" x="156.898" y="29.8243" width="6.85297" height="73.3875" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="1.03412" result="effect1_foregroundBlur_522_588" />
                </filter>
                <filter id="filter35_f_522_588" x="153.473" y="26.8137" width="13.7059" height="9.68605" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="2.06824" result="effect1_foregroundBlur_522_588" />
                </filter>
                <linearGradient id="paint0_linear_522_588" x1="85.1687" y1="-26.2201" x2="85.2509" y2="105.043" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9B4FFD" />
                    <stop offset="0.880958" stopColor="#333647" />
                </linearGradient>
                <radialGradient id="paint1_radial_522_588" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(2.71648 0.706644) rotate(90) scale(0.471096 1.81098)">
                    <stop offset="0.614184" stopColor="#333647" />
                    <stop offset="1" stopOpacity="0" />
                </radialGradient>
                <linearGradient id="paint2_linear_522_588" x1="260.054" y1="-5.13199" x2="260.09" y2="87.2523" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9B4FFD" />
                    <stop offset="0.763891" stopColor="#333647" />
                </linearGradient>
                <radialGradient id="paint3_radial_522_588" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(2.95745 0.76933) rotate(90) scale(0.512886 1.97164)">
                    <stop stopColor="#333647" />
                    <stop offset="1" stopOpacity="0" />
                </radialGradient>
                <linearGradient id="paint4_linear_522_588" x1="26.9196" y1="4.82703" x2="26.9809" y2="117.655" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9B4FFD" />
                    <stop offset="0.763891" stopColor="#333647" />
                </linearGradient>
                <radialGradient id="paint5_radial_522_588" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(2.71648 0.706644) rotate(90) scale(0.471096 1.81098)">
                    <stop offset="0.614184" stopColor="#333647" />
                    <stop offset="1" stopOpacity="0" />
                </radialGradient>
                <linearGradient id="paint6_linear_522_588" x1="281.145" y1="26.1841" x2="281.169" y2="96.4623" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9B4FFD" />
                    <stop offset="0.763891" stopColor="#333647" />
                </linearGradient>
                <radialGradient id="paint7_radial_522_588" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(2.71648 0.706644) rotate(90) scale(0.471096 1.81098)">
                    <stop offset="0.614184" stopColor="#333647" />
                    <stop offset="1" stopOpacity="0" />
                </radialGradient>
                <linearGradient id="paint8_linear_522_588" x1="138.622" y1="49.9157" x2="138.64" y2="111.191" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9B4FFD" />
                    <stop offset="0.763891" stopColor="#333647" />
                </linearGradient>
                <radialGradient id="paint9_radial_522_588" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(2.71648 0.706644) rotate(90) scale(0.471096 1.81098)">
                    <stop offset="0.614184" stopColor="#333647" />
                    <stop offset="1" stopOpacity="0" />
                </radialGradient>
                <linearGradient id="paint10_linear_522_588" x1="119.426" y1="-4.45145" x2="119.444" y2="56.8242" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9B4FFD" />
                    <stop offset="0.763891" stopColor="#333647" />
                </linearGradient>
                <radialGradient id="paint11_radial_522_588" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(2.71648 0.706644) rotate(90) scale(0.471096 1.81098)">
                    <stop offset="0.614184" stopColor="#333647" />
                    <stop offset="1" stopOpacity="0" />
                </radialGradient>
                <linearGradient id="paint12_linear_522_588" x1="6.37186" y1="61.6794" x2="6.38976" y2="122.955" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9B4FFD" />
                    <stop offset="0.763891" stopColor="#333647" />
                </linearGradient>
                <radialGradient id="paint13_radial_522_588" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(2.71648 0.706644) rotate(90) scale(0.471096 1.81098)">
                    <stop offset="0.614184" stopColor="#333647" />
                    <stop offset="1" stopOpacity="0" />
                </radialGradient>
                <linearGradient id="paint14_linear_522_588" x1="206.435" y1="-7.83117" x2="206.496" y2="104.997" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9B4FFD" />
                    <stop offset="0.763891" stopColor="#333647" />
                </linearGradient>
                <radialGradient id="paint15_radial_522_588" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(2.71648 0.706644) rotate(90) scale(0.471096 1.81098)">
                    <stop offset="0.614184" stopColor="#333647" />
                    <stop offset="1" stopOpacity="0" />
                </radialGradient>
                <linearGradient id="paint16_linear_522_588" x1="158.966" y1="4.80457" x2="159.028" y2="117.632" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9B4FFD" />
                    <stop offset="0.763891" stopColor="#333647" />
                </linearGradient>
                <radialGradient id="paint17_radial_522_588" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(2.71648 0.706644) rotate(90) scale(0.471096 1.81098)">
                    <stop offset="0.614184" stopColor="#333647" />
                    <stop offset="1" stopOpacity="0" />
                </radialGradient>
                <linearGradient id="paint18_linear_522_588" x1="85.1687" y1="-26.2201" x2="85.2509" y2="105.043" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9B4FFD" />
                    <stop offset="0.880958" stopColor="#333647" />
                </linearGradient>
                <radialGradient id="paint19_radial_522_588" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(2.71648 0.706644) rotate(90) scale(0.471096 1.81098)">
                    <stop offset="0.614184" stopColor="#333647" />
                    <stop offset="1" stopOpacity="0" />
                </radialGradient>
                <linearGradient id="paint20_linear_522_588" x1="260.054" y1="-5.13199" x2="260.09" y2="87.2523" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9B4FFD" />
                    <stop offset="0.763891" stopColor="#333647" />
                </linearGradient>
                <radialGradient id="paint21_radial_522_588" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(2.95745 0.76933) rotate(90) scale(0.512886 1.97164)">
                    <stop stopColor="#333647" />
                    <stop offset="1" stopOpacity="0" />
                </radialGradient>
                <linearGradient id="paint22_linear_522_588" x1="26.9196" y1="4.82703" x2="26.9809" y2="117.655" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9B4FFD" />
                    <stop offset="0.763891" stopColor="#333647" />
                </linearGradient>
                <radialGradient id="paint23_radial_522_588" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(2.71648 0.706644) rotate(90) scale(0.471096 1.81098)">
                    <stop offset="0.614184" stopColor="#333647" />
                    <stop offset="1" stopOpacity="0" />
                </radialGradient>
                <linearGradient id="paint24_linear_522_588" x1="281.145" y1="26.1841" x2="281.169" y2="96.4623" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9B4FFD" />
                    <stop offset="0.763891" stopColor="#333647" />
                </linearGradient>
                <radialGradient id="paint25_radial_522_588" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(2.71648 0.706644) rotate(90) scale(0.471096 1.81098)">
                    <stop offset="0.614184" stopColor="#333647" />
                    <stop offset="1" stopOpacity="0" />
                </radialGradient>
                <linearGradient id="paint26_linear_522_588" x1="138.622" y1="49.9157" x2="138.64" y2="111.191" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9B4FFD" />
                    <stop offset="0.763891" stopColor="#333647" />
                </linearGradient>
                <radialGradient id="paint27_radial_522_588" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(2.71648 0.706644) rotate(90) scale(0.471096 1.81098)">
                    <stop offset="0.614184" stopColor="#333647" />
                    <stop offset="1" stopOpacity="0" />
                </radialGradient>
                <linearGradient id="paint28_linear_522_588" x1="119.426" y1="-4.45145" x2="119.444" y2="56.8242" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9B4FFD" />
                    <stop offset="0.763891" stopColor="#333647" />
                </linearGradient>
                <radialGradient id="paint29_radial_522_588" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(2.71648 0.706644) rotate(90) scale(0.471096 1.81098)">
                    <stop offset="0.614184" stopColor="#333647" />
                    <stop offset="1" stopOpacity="0" />
                </radialGradient>
                <linearGradient id="paint30_linear_522_588" x1="6.37186" y1="61.6794" x2="6.38976" y2="122.955" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9B4FFD" />
                    <stop offset="0.763891" stopColor="#333647" />
                </linearGradient>
                <radialGradient id="paint31_radial_522_588" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(2.71648 0.706644) rotate(90) scale(0.471096 1.81098)">
                    <stop offset="0.614184" stopColor="#333647" />
                    <stop offset="1" stopOpacity="0" />
                </radialGradient>
                <linearGradient id="paint32_linear_522_588" x1="206.435" y1="-7.83117" x2="206.496" y2="104.997" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9B4FFD" />
                    <stop offset="0.763891" stopColor="#333647" />
                </linearGradient>
                <radialGradient id="paint33_radial_522_588" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(2.71648 0.706644) rotate(90) scale(0.471096 1.81098)">
                    <stop offset="0.614184" stopColor="#333647" />
                    <stop offset="1" stopOpacity="0" />
                </radialGradient>
                <linearGradient id="paint34_linear_522_588" x1="158.966" y1="4.80457" x2="159.028" y2="117.632" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9B4FFD" />
                    <stop offset="0.763891" stopColor="#333647" />
                </linearGradient>
                <radialGradient id="paint35_radial_522_588" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(2.71648 0.706644) rotate(90) scale(0.471096 1.81098)">
                    <stop offset="0.614184" stopColor="#333647" />
                    <stop offset="1" stopOpacity="0" />
                </radialGradient>
            </defs>
        </svg>
    );
}
import { Box } from "@mui/material";


export default function SelectArrowIcon(props: any) {

    return (
        <Box
            {...props}
            sx={{
                top: "25% !important",
                height: "24px",
                width: "24px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <svg width="14" height="7" viewBox="0 0 14 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1.00006L7 6.00006L13 1.00006" stroke="#7E2AEA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </Box>
    );
}
import { Typography, useMediaQuery, useTheme, Box } from "@mui/material";

import Section from "@kitUI/section";
import cardImage1 from "@kitUI/img/landing/card1.png";
import cardImage2 from "@kitUI/img/landing/card2.png";
import cardImage3 from "@kitUI/img/landing/card3.png";
import NumberIcon from "@root/kitUI/Icons/NumberIcon";

import ImageTextNumberCard from "./ImageTextNumberCard";

export default function HowItWorks() {
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up("md"));
  const isTablet = useMediaQuery(theme.breakpoints.up(1100));
  const isMobile = useMediaQuery(theme.breakpoints.up(600));

  const cardSx = {
    minWidth: "300px",
    maxWidth: isTablet ? "293px" : isMobile ? "0" : "360px",
    flex: "1 1 0",
  };

  return (
    <Section
      bg="#262836"
      sxsect={{
        pt: upMd ? "101px" : "100px",
        mb: "-70px",
      }}
    >
      <Typography
        variant="h3"
        sx={{
          color: "white",
          lineHeight: "118.5%",
        }}
      >
        Как работает сервис
      </Typography>
      <Box
        sx={{
          mt: upMd ? "60px" : "43px",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-evenly",
          gap: isTablet ? "40px" : "20px",
        }}
      >
        <ImageTextNumberCard
          icon={<NumberIcon number={1} color="#FFFFFF" backgroundColor="#26283580" />}
          image={cardImage1}
          headerText="Создайте короткую ссылку"
          text="Текст-заполнитель — это текст, который имеет некоторые характеристики "
          sx={cardSx}
        />
        <ImageTextNumberCard
          icon={<NumberIcon number={2} color="#FFFFFF" backgroundColor="#26283580" />}
          image={cardImage2}
          headerText="Отслеживайте статистику"
          text="Текст-заполнитель — это текст, который имеет некоторые характеристики "
          sx={cardSx}
        />
        <ImageTextNumberCard
          icon={<NumberIcon number={3} color="#FFFFFF" backgroundColor="#26283580" />}
          image={cardImage3}
          headerText="Контролируйте процессы онлайн"
          text="Текст-заполнитель — это текст, который имеет некоторые характеристики "
          sx={cardSx}
        />
      </Box>
    </Section>
  );
}

import { useLocation } from "react-router-dom";
import { useTheme, useMediaQuery } from "@mui/material";

import DesktopNavbar from "./DesktopNavbar";
import MobileNavbar from "./MobileNavbar";
import TabletNavbar from "./TabletNavbar";

export default function NavbarLoggedIn() {
  const muiTheme = useTheme();
  const location = useLocation();

  const phablet = useMediaQuery(muiTheme.breakpoints.up(1000));
  const isMobile = useMediaQuery(muiTheme.breakpoints.up(600));

  return phablet ? <DesktopNavbar /> : isMobile ? <TabletNavbar /> : <MobileNavbar key={location.pathname} />;
}

import { Avatar, Box, SxProps, Theme, Typography } from "@mui/material";


interface Props {
    sx: SxProps<Theme>;
}
export default function CustomAvatar({ sx }: Props) {

    return (
        <Avatar
            sx={sx}
        >
            <Typography
                sx={{
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "20px",
                    zIndex: 1,
                }}
            >AA</Typography>
            <Box sx={{ position: "absolute" }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="37" height="36" viewBox="0 0 37 36" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M16.0896 15.3939C16.1897 9.41281 22.9128 5.35966 28.711 3.9153C34.7649 2.40721 41.974 3.19598 46.0209 7.93784C49.6931 12.2405 46.8503 18.5029 45.9355 24.0976C45.2565 28.2502 44.7264 32.5083 41.552 35.2692C38.1345 38.2416 32.8105 41.3312 29.1224 38.7209C25.459 36.1281 30.5336 29.8417 28.3428 25.9204C25.5777 20.9711 15.9947 21.0705 16.0896 15.3939Z" fill="#FC712F" />
                    <circle cx="28.7954" cy="-4.08489" r="5.51855" transform="rotate(-32.339 28.7954 -4.08489)" fill="#FC712F" />
                    <circle cx="25.1065" cy="28.2781" r="1.26958" transform="rotate(-32.339 25.1065 28.2781)" fill="#FC712F" />
                </svg>
            </Box>
        </Avatar>
    );
}
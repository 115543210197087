import { ReactNode } from "react";
import {
  Typography,
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SxProps,
  Theme,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import Section from "@root/kitUI/section";

import CheckIcon from "./CheckIcon";
import CircleIcon from "./CircleIcon";
import Trails from "./Trails";

const cardContainerSx = {
  minWidth: "300px",
  maxWidth: "360px",
  flex: "1 1 0",
};

const cardSx = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: "500px",
  borderRadius: "12px",
  p: "20px",
  pt: "30px",
};

export default function Tariffs() {
  const theme = useTheme();
  const upLg = useMediaQuery(theme.breakpoints.up("lg"));
  const upMd = useMediaQuery(theme.breakpoints.up("md"));
  const isTablet = useMediaQuery(theme.breakpoints.up(1100));

  return (
    <Section
      bg="#333647"
      sxsect={{
        pt: "100px",
        pb: upMd ? "100px" : "89px",
      }}
    >
      <Typography
        sx={{
          color: "white",
          fontWeight: 500,
          fontSize: "36px",
          lineHeight: "43px",
        }}
      >
        Наши тарифы
      </Typography>
      <Box
        sx={{
          mt: upMd ? "75px" : "85px",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-evenly",
          gap: isTablet ? "40px" : "20px",
        }}
      >
        <Box
          sx={{
            ...cardContainerSx,
            order: upLg ? 1 : 2,
          }}
        >
          <Box
            sx={{
              ...cardSx,
              backgroundColor: "#282937",
              border: "1px solid #9A9AAF",
            }}
          >
            <Typography variant="t1" color="white" fontWeight="500">
              Платно
            </Typography>
            <Typography
              sx={{
                color: "white",
                mt: "30px",
                fontWeight: 500,
                fontSize: "36px",
                lineHeight: "43px",
              }}
            >
              4 560 руб.
            </Typography>
            <CustomList
              sx={{
                mt: "70px",
              }}
              icon={<CheckIcon bgcolor="#7E2AEA" lineColor="white" />}
              items={[
                "Доступ к полной статистике",
                "Делигирование сокращателя на домен ",
                "То же, что и в бесплатном тарифе",
              ]}
            />
            <Button
              disableRipple
              variant="template"
              sx={{
                p: "9px 51.5px",
                mt: "auto",
                "&:hover": {
                  border: "1px solid 944FEE",
                  bgcolor: "#944FEE",
                },
                "&:active": {
                  borderColor: "#944FEE",
                  bgcolor: "white",
                  color: "#944FEE",
                },
              }}
            >
              Выбрать
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            ...cardContainerSx,
            position: "relative",
            order: upLg ? 2 : 1,
          }}
        >
          <Trails
            style={{
              position: "absolute",
              top: "-64px",
              left: "34px",
            }}
          />
          <Box
            sx={{
              ...cardSx,
              backgroundColor: "#7E2AEA",
              position: "relative",
            }}
          >
            <Typography variant="t1" color="white" fontWeight="500">
              Бесплатно
            </Typography>
            <Typography
              sx={{
                color: "white",
                mt: "30px",
                fontWeight: 500,
                fontSize: "36px",
                lineHeight: "43px",
              }}
            >
              0 руб.
            </Typography>
            <CustomList
              sx={{
                mt: "70px",
              }}
              icon={<CheckIcon bgcolor="white" lineColor="#7E2AEA" />}
              items={["Просмотр количества кликов", "Сокращение, UTM-метки", "Экспорт статистики", "Пиксель"]}
            />
            <Button
              disableRipple
              variant="template"
              sx={{
                color: "black",
                backgroundColor: "white",
                p: "9px 51.5px",
                mt: "auto",
                "&:hover": {
                  color: "white",
                  bgcolor: "#944FEE",
                  borderColor: "white",
                },
                "&:active": {
                  bgcolor: "white",
                  borderColor: "#944FEE",
                  color: "#944FEE",
                },
              }}
            >
              Выбрать
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            ...cardContainerSx,
            order: 3,
          }}
        >
          <Box
            sx={{
              ...cardSx,
              height: "auto",
            }}
          >
            <Typography variant="t1" color="#9B4FFD" fontWeight="500">
              Скоро
            </Typography>
            <Typography
              sx={{
                color: "white",
                mt: "30px",
                fontWeight: 500,
                fontSize: "36px",
                lineHeight: "43px",
              }}
            >
              В разработке
            </Typography>
            <CustomList
              sx={{
                mt: "72px",
                ml: "5px",
              }}
              icon={<CircleIcon />}
              items={["Продажа коротких ссылок", "Ручная генерация", "Брендирование", "Двойная ссылка"]}
            />
          </Box>
        </Box>
      </Box>
    </Section>
  );
}

function CustomList({ items, sx, icon }: { items: string[]; sx?: SxProps<Theme>; icon: ReactNode }) {
  return (
    <List
      sx={{
        color: "white",
        width: "100%",
        p: 0,
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        ...sx,
      }}
    >
      {items.map((text, index) => (
        <ListItem
          key={text}
          sx={{
            p: 0,
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: "30px",
              alignSelf: "start",
              mt: "3px",
              ml: "11px",
            }}
          >
            {icon}
          </ListItemIcon>
          <ListItemText
            disableTypography
            primary={<Typography variant="t1">{text}</Typography>}
            sx={{
              m: 0,
            }}
          />
        </ListItem>
      ))}
    </List>
  );
}

import { Box, IconButton, Dialog, Slide, AppBar, Toolbar, Typography, useTheme } from "@mui/material";
import PenaLogoDark from "../../img/logo_pena_links_dark";
import CustomAvatar from "./Avatar";
import LogoutIcon from "./LogoutIcon";
import { ReactNode, forwardRef, useState } from "react";
import { TransitionProps } from "@mui/material/transitions";
import BurgerIcon from "@root/kitUI/Icons/BurgerIcon";
import CloseIcon from "@root/kitUI/Icons/CloseIcon";
import NavMenuItem from "../NavMenuItem";


const logoStyle = { minWidth: "101px", width: "101px", height: "39px" };

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function MobileNavbar() {
    const theme = useTheme();
    const [isSubmenuOpen, setIsSubmenuOpen] = useState<boolean>(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    async function handleLogoutClick() {

    }

    return (
        <Box
            component="nav"
            sx={{
                width: '100%',
                height: "51px",
                backgroundColor: "white",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                zIndex: theme.zIndex.header,
                px: "16px",
                gap: "20px",
                borderBottom: "1px solid #E3E3E3",
            }}
        >
            <PenaLogoDark style={logoStyle} />
            <IconButton onClick={() => setIsDialogOpen(true)} sx={{ p: 0 }}>
                <BurgerIcon sx={{ color: "black" }} />
            </IconButton>
            <Dialog
                fullScreen
                open={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                TransitionComponent={Transition}
                PaperProps={{
                    sx: {
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        background: "white",
                        gap: "18px",
                    }
                }}
            >
                <AppBar sx={{
                    position: "relative",
                    boxShadow: "none",
                    borderRadius: 0,
                    background: "white",
                }}>
                    <Toolbar
                        disableGutters
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            px: "16px",
                            minHeight: "51px",
                            [theme.breakpoints.up("sm")]: {
                                minHeight: "51px",
                            },
                        }}
                    >
                        <PenaLogoDark style={logoStyle} />
                        <IconButton
                            onClick={() => setIsDialogOpen(false)}
                            aria-label="close"
                            sx={{ p: 0 }}
                        >
                            <CloseIcon sx={{ color: "black" }} />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                }}>
                    <NavMenuItem fullWidth to="/links">Мои ссылки</NavMenuItem>
                    <NavMenuItem fullWidth to="/create" onClick={() => setIsSubmenuOpen(p => !p)}>Создание ссылки</NavMenuItem>
                    {isSubmenuOpen &&
                        <>
                            <MenuSubItem to="/create">Создание ссылки</MenuSubItem>
                            <MenuSubItem to="/buy">Покупка и настройка custom ссылки</MenuSubItem>
                            <MenuSubItem to="/">A/B тесты</MenuSubItem>
                        </>
                    }
                    <NavMenuItem fullWidth to="/">Кастомизация ссылки</NavMenuItem>
                    <NavMenuItem fullWidth to="/stats">Статистика</NavMenuItem>
                    <NavMenuItem fullWidth to="/chart">Пиксель</NavMenuItem>
                </Box>
                <Box sx={{
                    mt: "auto",
                    backgroundColor: "#F1F2F6",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    p: "18px 16px",
                }}>
                    <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "20px",
                    }}>
                        <CustomAvatar sx={{ backgroundColor: "#FB5607", height: "36px", width: "36px" }} />
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",

                        }}>
                            <Typography sx={{
                                fontSize: "12px",
                                lineHeight: "14px",
                                color: "#4D4D4D",
                            }}>Александр А.</Typography>
                            <Typography sx={{
                                fontWeight: 500,
                                fontSize: "16px",
                                lineHeight: "20px",
                                color: "#7E2AEA",
                            }}>FREE</Typography>
                        </Box>
                    </Box>
                    <IconButton
                        onClick={handleLogoutClick}
                        sx={{ ml: "20px", bgcolor: "#E2E3E9", borderRadius: "6px", height: "36px", width: "36px" }}
                    >
                        <LogoutIcon />
                    </IconButton>
                </Box>
            </Dialog>
        </Box>
    );
}

function MenuSubItem({ children, to }: {
    to: string;
    children: ReactNode;
}) {

    return (
        <NavMenuItem
            to={to}

            defaultColor="#4D4D4D"
            fullWidth
            sx={{
                fontWeight: 400,
                py: "13px",
                pl: "60px",
                justifyContent: "start",
                "&:hover": {
                    backgroundColor: "#F2F3F7",
                }
            }}
        >{children}</NavMenuItem>
    );
}